import { FontAwesome } from '@expo/vector-icons';
import { API } from 'aws-amplify';
import * as React from 'react';
import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    Image
} from 'react-native';
import PrimaryButton from '../components/primary-button';

import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from '../services/navigation-service';
import { AppContext, IContext } from "../store/app-provider";
import * as queries from "../graphql/queries";
import UserButton from '../components/user-button';
import { AddixPost } from '../models';
import PostItem from '../components/post-item';
interface Props {

}

interface State {
    post?: AddixPost
}

class PostViewScreen extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext

    constructor(props: any) {
        super(props);
        this.state = {}
    }
    openApp() {

    }
    async componentDidMount() {
        setTimeout(async () => {
            let postId = this.context.state.routeParams?.id;
            console.log("postId", postId);
            if (postId) {
                const post = (await API.graphql({
                    query: queries.getAddixPost,
                    variables: { id: postId }
                }) as any).data.getAddixPost;
                this.setState({ post });
                console.log("post", post);
            }

        }, 100);

    }

    render() {

        return (

            <View style={style.page}>
                <View style={[style.container, { justifyContent: 'flex-start' }]}>
                    {this.state.post ? <PostItem index={0} item={this.state.post} onPress={() => { }} /> : null}

                </View>
            </View>

        )
    };

}

export default PostViewScreen;

const styles = StyleSheet.create({});
