import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { User } from "../models";
import { SheetManager } from 'react-native-actions-sheet';
import UserButton from './user-button';

interface State {
    formmatedDays?: any
}

interface Props {
    style?: ViewStyle
    date: string
}

class DaysPassed extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    userButton: any;
    constructor(props: any) {
        super(props);
        this.state = {}

    }

    componentDidMount() {
        const date = new Date(this.props.date);
        //return minutes passed or hours or days or maonths or years
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);
        let formmatedDays = "";
        if (years > 0) {
            formmatedDays = years + " year" + (years > 1 ? "s" : "") + " ago";
        } else if (months > 0) {
            formmatedDays = months + " month" + (months > 1 ? "s" : "") + " ago";
        } else if (days > 0) {
            formmatedDays = days + " day" + (days > 1 ? "s" : "") + " ago";
        } else if (hours > 0) {
            formmatedDays = hours + " hour" + (hours > 1 ? "s" : "") + " ago";
        } else if (minutes > 0) {
            formmatedDays = minutes + " minute" + (minutes > 1 ? "s" : "") + " ago";
        } else {
            formmatedDays = seconds + " second" + (seconds > 1 ? "s" : "") + " ago";
        }
        this.setState({ formmatedDays });

    }

    render() {

        return (
            <View style={[style.row, style.vcenter, this.props.style, {}]}>
                <Text style={[style.text, { fontSize: 13, color: colors.greaysh }]}>{this.state.formmatedDays}</Text>
            </View>
        )
    };

}

const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});

export default DaysPassed;
