import { AntDesign, Feather, FontAwesome } from '@expo/vector-icons';
import * as React from 'react';
import {
    StyleSheet,
    Text,
    View,
    TextInput,
    ViewStyle,
    ScrollView,
    ImageBackground
} from 'react-native';

import colors from '../constants/colors';
import style from '../constants/style';
import * as Progress from 'react-native-progress';
import { AppContext, IContext } from "../store/app-provider";
import { TouchableOpacity } from 'react-native-gesture-handler';
import navigationService from '../services/navigation-service';
import { Buffer } from 'buffer';
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
///@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import PrimaryButton from '../components/primary-button';
import { User } from '../models';
import UserButton from '../components/user-button';
import ActionSheet, { SheetManager } from 'react-native-actions-sheet';
import RadioGroup from '../components/radio-group';
interface Props {
    style?: ViewStyle
    campaignType?: string
    campaignStart?: string
    endDate?: string
    user?: User
}

interface State {
    shareType?: string
    postMessage?: string
    isBusy?: boolean
}

class CampaignScreen extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    captureAll: any;
    constructor(props: any) {
        super(props);
        this.state = { shareType: 'both' }
    }

    async componentDidMount() {

        //console.log("userLevel", userLevel)

        //this.setState({ beginnerPercentage, intermediatePercentage, advancedPercentage, userLevel, expertPercentage, masterPercentage });

    }
    getFormattedDate(date: any) {
        //return formatted date with hours and minutes
        return new Date(date).toLocaleDateString('en-US', {
            day: 'numeric', month: 'short', year: 'numeric'
        }) + ' ' + new Date(date).toLocaleTimeString('en-US', {
            hour: 'numeric', minute: 'numeric', hour12: true
        });
    }
    getFormatText(userLevel: any, type: string, percent: any) {
        //console.log("type", type, percent)
        const beginnerPercentage = userLevel.daysSinceQuit / 7;
        const intermediatePercentage = userLevel.daysSinceQuit / 30;
        const advancedPercentage = userLevel.daysSinceQuit / 90;
        const expertPercentage = userLevel.daysSinceQuit / 365;
        const masterPercentage = userLevel.daysSinceQuit / (365 * 10);
        return type === "Beginner" ? `${userLevel?.daysSinceQuit > 7 ? 7 : userLevel?.daysSinceQuit}/7 - ${beginnerPercentage >= 1 ? 100 : (beginnerPercentage * 100).toFixed(0)}%` :
            type === "Intermediate" ? `${userLevel?.daysSinceQuit > 30 ? 30 : userLevel?.daysSinceQuit}/30 - ${intermediatePercentage >= 1 ? 100 : (intermediatePercentage * 100).toFixed(0)}%` :
                type === "Advanced" ? `${userLevel?.daysSinceQuit > 90 ? 90 : userLevel?.daysSinceQuit}/90 - ${advancedPercentage >= 1 ? 100 : (advancedPercentage * 100).toFixed(0)}%` :
                    type === "Expert" ? `${userLevel?.daysSinceQuit > 365 ? 365 : userLevel?.daysSinceQuit}/365 - ${expertPercentage >= 1 ? 100 : (expertPercentage * 100).toFixed(0)}%` :
                        `${userLevel?.daysSinceQuit > 3650 ? 3650 : userLevel?.daysSinceQuit}/3650 - ${masterPercentage >= 1 ? 100 : (masterPercentage * 100).toFixed(0)}%`;


    }
    async sharePost() {
        //this.setState({ isBusy: true });
        const uid = uuidv4();
        const id = `shots/${uid}.png`;
        const smallUri = await navigationService.props.captureRef(this.captureAll, {
            format: "png",
            quality: 1
        });
        let photo;
        let photoBlob;
        let photoBlobData;
        let filename = "";
        try {
            photoBlobData = await navigationService.props.RNFS.readFile(smallUri, 'base64')
            photoBlob = Buffer.from(photoBlobData, 'base64');
            filename = id;
            await Storage.put(filename, photoBlob, {
                level: 'public',
                contentType: 'image/png'
            })
        } catch (e) {
            console.log("e", e);
        }
        //this.setState({ isBusy: false });
        const url = `https://preview.addix.mobi/p?t=c&id=${uid}`;
        const shareObj = { url, title: "" }
        this.context.shareMe(shareObj);


    }
    async shareMe() {
        this.setState({ isBusy: true });
        setTimeout(async () => {
            switch (this.state.shareType) {
                case 'app':
                    if (!this.context.state.user) {
                        this.context.showToast("Please login to share on the app's public timeline", 'error');
                        return;
                    }
                    await this.makePublic();
                    break;
                case 'social':
                    this.sharePost();
                    break;
                case 'both':
                    if (this.context.state.user) {
                        await this.makePublic();
                    }
                    this.sharePost();
                    break;
            }
            await SheetManager.hide(`share_campaign`);
            this.setState({ isBusy: false });
        }, 500);

    }
    async makePublic() {
        await this.context.postCampaign(this.state.postMessage);
        this.context.showToast('Messaged posted!', 'success');
    }
    render() {
        const userLevel = this.context.getUserLevel(this.props.campaignStart || this.context.state.user?.campaignStart, this.props.endDate);
        const beginnerPercentage = userLevel.daysSinceQuit / 7;
        const intermediatePercentage = userLevel.daysSinceQuit / 30;
        const advancedPercentage = userLevel.daysSinceQuit / 90;
        const expertPercentage = userLevel.daysSinceQuit / 365;
        const masterPercentage = userLevel.daysSinceQuit / (365 * 10);

        const progress = () => {
            switch (userLevel?.levelLabel) {
                case "Master":
                    return <View style={[style.column]}>
                        <Text style={[style.text, style.fullWidth, { fontSize: 18, fontWeight: 'bold', color: userLevel?.levelLabel === "Master" ? "#ffffff" : colors.textColor, marginBottom: 10, textAlign: 'center' }]}>Master</Text>
                        <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Master")} showsText={true} style={{ marginBottom: 10 }} borderColor={"#ffffff"} color={"#ffffff"} borderWidth={3} thickness={6} progress={masterPercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                    </View>
                    break;
                case "Expert":
                    return <View style={[style.column]}>
                        <Text style={[style.text, style.fullWidth, { fontSize: 18, fontWeight: 'bold', color: userLevel?.levelLabel === "Expert" ? "#ffffff" : colors.textColor, marginBottom: 10, textAlign: 'center' }]}>Expert</Text>
                        <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Expert")} showsText={true} style={{ marginBottom: 10 }} borderColor={"#ffffff"} color={"#ffffff"} borderWidth={3} thickness={6} progress={expertPercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                    </View>
                    break;
                case "Advanced":
                    return <View style={[style.column]}>
                        <Text style={[style.text, style.fullWidth, { fontSize: 18, fontWeight: 'bold', color: userLevel?.levelLabel === "Advanced" ? "#ffffff" : colors.textColor, marginBottom: 10, textAlign: 'center' }]}>Advanced</Text>
                        <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Advanced")} showsText={true} style={{ marginBottom: 10 }} borderColor={"#ffffff"} color={"#ffffff"} borderWidth={3} thickness={6} progress={advancedPercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                    </View>
                    break;
                case "Intermediate":
                    return <View style={[style.column]}>

                        <Text style={[style.text, style.fullWidth, { fontSize: 18, fontWeight: 'bold', color: userLevel?.levelLabel === "Intermediate" ? "#ffffff" : colors.textColor, marginBottom: 10, textAlign: 'center' }]}>Intermediate</Text>
                        <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Intermediate")} showsText={true} style={{ marginBottom: 10 }} borderColor={"#ffffff"} color={"#ffffff"} borderWidth={3} thickness={6} progress={intermediatePercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                    </View>
                    break;
                case "Beginner":
                    return <View style={[style.column]}>
                        <Text style={[style.text, style.fullWidth, { fontSize: 18, fontWeight: 'bold', color: userLevel?.levelLabel === "Beginner" ? "#ffffff" : colors.textColor, marginBottom: 10, textAlign: 'center' }]}>Beginner</Text>
                        <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Beginner")} showsText={true} style={{ marginBottom: 10 }} borderColor={"#ffffff"} color={"#ffffff"} borderWidth={3} thickness={6} progress={beginnerPercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                    </View>
                    break;

            }
        }
        return (
            <View style={[this.props.style || style.page, { justifyContent: 'flex-start' }]}>
                {this.props.campaignStart || this.context.state.user?.campaignStart ? <View style={[style.container, { padding: 0, justifyContent: 'flex-start', backgroundColor: 'transparent', width: this.props.campaignStart ? '100%' : 380 }]}>
                    {/* <Text style={[style.text, { fontSize: 18, fontWeight: 'bold', marginBottom: 10 }]}>Campaign - {this.context.getTextWithFirstLetterCapital(this.context.state.user?.campaignType)} - {this.getFormattedDate(this.context.state.user?.campaignStart)}</Text> */}

                    <navigationService.props.ViewShot style={{
                        width: this.props.campaignStart ? '100%' : undefined, alignItems: 'center',
                        justifyContent: 'center'
                    }} ref={ref => this.captureAll = ref} >
                        <ImageBackground style={{ width: this.props.campaignStart ? '100%' : 380, height: this.props.campaignStart ? 300 : 200, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} imageStyle={{ borderRadius: colors.borderRadius, resizeMode: 'cover', width: this.props.campaignStart ? '100%' : 380, height: this.props.campaignStart ? 300 : 200 }} source={{ uri: "https://raw.githubusercontent.com/crisand/addix/main/bottom7.png" }}>
                            <UserButton user={this.props.user || this.context.state.user} size={40} style={{ position: 'absolute', top: 10, right: 10 }} />
                            <View style={[style.column, style.vcenter, { backgroundColor: 'rgba(0,0,0,0.5)', width: 250, borderRadius: 30, marginTop: 0, marginBottom: 0 }]}>
                                {progress()}
                                <View style={[style.row, { marginBottom: 10 }]}>
                                    <View style={[style.column, style.vcenter, { marginRight: 10, width: 80 }]}>
                                        <Text style={[style.text, { color: "#ffffff", marginBottom: 3, fontWeight: 'bold' }]}>DAYS</Text>
                                        <Text style={[style.text, { color: "#ffffff", fontWeight: 'bold' }]}>{userLevel?.daysSinceQuit}</Text>
                                    </View>
                                    <View style={[style.column, style.vcenter, { marginRight: 10, width: 80 }]}>
                                        <Text style={[style.text, { color: "#ffffff", marginBottom: 3, fontWeight: 'bold' }]}>HOURS</Text>
                                        <Text style={[style.text, { color: "#ffffff", fontWeight: 'bold' }]}>{userLevel?.hoursSinceQuit}</Text>
                                    </View>
                                    <View style={[style.column, style.vcenter, { width: 80 }]}>
                                        <Text style={[style.text, { color: "#ffffff", marginBottom: 3, fontWeight: 'bold' }]}>MIN</Text>
                                        <Text style={[style.text, { color: "#ffffff", fontWeight: 'bold' }]}>{userLevel?.minutesSinceQuit}</Text>
                                    </View>
                                </View>
                            </View>

                        </ImageBackground>
                    </navigationService.props.ViewShot>
                    {this.props.campaignStart ? null : <PrimaryButton isBusy={this.state.isBusy} align={'left'} onPress={() => {
                        //this.sharePost();
                        this.setState({ postMessage: "" });
                        SheetManager.show(`share_campaign`);
                    }} icon={
                        <AntDesign name="sharealt" size={20} style={{ marginLeft: 0, marginTop: 3 }} color={colors.whiteish} />} label={'Share'} style={{
                            width: '100%',
                            marginTop: 10, marginBottom: 10
                        }} />
                    }
                    {!this.props.campaignStart ? <View style={[style.column, { marginTop: 0, paddingLeft: 90 }]}>
                        <View style={[style.row, style.vcenter]}>
                            <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Master")} showsText={true} style={{ marginBottom: 0 }} borderColor={"#0000ff"} color={"#0000ff"} borderWidth={3} thickness={6} progress={masterPercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                            <Text style={[style.text, { fontSize: 15, marginLeft: 10, fontWeight: 'bold', color: userLevel?.levelLabel === "Master" ? colors.bluePrimary : colors.textColor, marginBottom: 0 }]}>Master</Text>
                        </View>
                        <View style={[style.row, style.vcenter]}>
                            <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Expert")} showsText={true} style={{ marginBottom: 0 }} borderColor={"#00cc00"} color={"#00cc00"} borderWidth={3} thickness={6} progress={expertPercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                            <Text style={[style.text, { fontSize: 15, marginLeft: 10, fontWeight: 'bold', color: userLevel?.levelLabel === "Expert" ? colors.bluePrimary : colors.textColor, marginBottom: 0 }]}>Expert</Text>
                        </View>
                        <View style={[style.row, style.vcenter]}>
                            <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Advanced")} showsText={true} style={{ marginBottom: 0 }} borderColor={"#ffcc00"} color={"#ffcc00"} borderWidth={3} thickness={6} progress={advancedPercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                            <Text style={[style.text, { fontSize: 15, marginLeft: 10, fontWeight: 'bold', color: userLevel?.levelLabel === "Advanced" ? colors.bluePrimary : colors.textColor, marginBottom: 0 }]}>Advanced</Text>

                        </View>
                        <View style={[style.row, style.vcenter]}>
                            <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Intermediate")} showsText={true} style={{ marginBottom: 0 }} borderColor={"#ff9900"} color={"#ff9900"} borderWidth={3} thickness={6} progress={intermediatePercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                            <Text style={[style.text, { fontSize: 15, marginLeft: 10, fontWeight: 'bold', color: userLevel?.levelLabel === "Intermediate" ? colors.bluePrimary : colors.textColor, marginBottom: 0 }]}>Intermediate</Text>
                        </View>
                        <View style={[style.row, style.vcenter]}>
                            <Progress.Circle formatText={this.getFormatText.bind(this, userLevel, "Beginner")} showsText={true} style={{ marginBottom: 0 }} borderColor={"#ff0000"} color={"#ff0000"} borderWidth={3} thickness={6} progress={beginnerPercentage} size={80} textStyle={{ fontFamily: 'Jost', fontSize: 10, fontWeight: 'bold' }} allowFontScaling={false} />
                            <Text style={[style.text, { fontSize: 15, marginLeft: 10, fontWeight: 'bold', color: userLevel?.levelLabel === "Beginner" ? colors.bluePrimary : colors.textColor, marginBottom: 0 }]}>Beginner</Text>
                        </View>
                    </View> : null}
                </View> : <View><Text style={[style.text]}>You don't have an active campaign yet</Text></View>
                }
                <ActionSheet closeOnPressBack={true} onClose={() => {
                }} keyboardShouldPersistTaps={'handled'} containerStyle={{
                    maxWidth: 400, borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRadius: colors.borderRadius
                }} defaultOverlayOpacity={0.5} id={`share_campaign`}>

                    <View style={[style.fullWidth, style.column, style.hcenter, { padding: 20 }]}>
                        <RadioGroup activeColor={colors.pinkish} style={{
                            width: '100%',
                            marginBottom: 10
                        }} textColor={colors.textColor} initial={2} textStyle={{
                            color: colors.textColor,
                            fontSize: 18
                        }}
                            data={[{
                                label: "Share on app's timeline",
                                type: 'app'
                            }, {
                                label: "Share on Social Media",
                                type: 'social'
                            }, {
                                label: "Share on both",
                                type: 'both'
                            }]}
                            selectedBtn={(e: any) => {
                                console.log("e", e)
                                this.setState({ shareType: e.type })
                            }}
                        />
                        <TextInput
                            autoCapitalize={'sentences'}
                            value={this.state.postMessage}
                            autoCorrect={false}
                            blurOnSubmit={true}
                            maxLength={250}
                            enablesReturnKeyAutomatically={true}
                            onSubmitEditing={() => {
                                this.shareMe()
                            }}
                            placeholder='Post message'
                            multiline={true}
                            numberOfLines={3}
                            onChangeText={(text: string) => {
                                if (this.state.isBusy) return;
                                this.setState({ postMessage: text });
                            }}
                            style={[style.textInput, {
                                backgroundColor: colors.pinkish,
                                textAlignVertical: 'center',
                                height: 80,
                                fontFamily: 'Jost',
                                marginBottom: 10,
                                color: colors.neutralBlue,
                                width: '100%', // Add this line
                            }]}
                        ></TextInput>


                        <PrimaryButton progress={false} align={'left'} isBusy={this.state.isBusy} onPress={this.shareMe.bind(this)} icon={
                            <AntDesign name="sharealt" size={20} style={{ marginLeft: 0, marginTop: 3 }} color={colors.whiteish} />} label={'Share'} style={{
                                width: '100%',
                                marginBottom: 10,
                                marginTop: 10
                            }} />
                    </View>
                </ActionSheet>
            </View>

        )
    };

}

export default CampaignScreen;

const styles = StyleSheet.create({});
