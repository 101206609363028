import { FontAwesome } from '@expo/vector-icons';
import { API } from 'aws-amplify';
import * as React from 'react';
import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    Image
} from 'react-native';
import PrimaryButton from '../components/primary-button';

import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from '../services/navigation-service';
import { AppContext, IContext } from "../store/app-provider";
import * as queries from "../graphql/queries";
import UserButton from '../components/user-button';
interface Props {

}

interface State {
    message?: any
}

class AIMessageScreen extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext

    constructor(props: any) {
        super(props);
        this.state = {}
    }
    openApp() {

    }
    async componentDidMount() {
        setTimeout(async () => {

            let messageId = this.context.state.routeParams?.id;
            console.log("messageId", messageId);
            if (messageId) {
                const message = (await API.graphql({
                    query: queries.getAIChats,
                    variables: { id: messageId }
                }) as any).data.getAIChats;
                this.setState({ message: message });
                console.log("message", message);
            }

        }, 100);

    }

    render() {

        return (

            <View style={style.page}>
                <View style={[style.container, { justifyContent: 'flex-start' }]}>
                    {this.state.message ? <ScrollView style={[style.fullWidth, { flex: 1, marginTop: 10, marginBottom: 10, padding: 20, borderRadius: colors.borderRadius, backgroundColor: colors.pinkish }]}>
                        <View style={[style.column, style.hcenter, style.fullWidth, {}]}>
                            <View style={[style.row, style.fullWidth, { paddingRight: 10 }]}>
                                <UserButton user={this.state.message.user} style={{ marginRight: 10 }} size={35} ></UserButton>
                                <Text style={{
                                    fontFamily: 'Jost',
                                    marginTop: 5,
                                    fontSize: 18,
                                    flexGrow: 1,
                                    paddingRight: 20,
                                    color: colors.neutralBlue
                                }}>{this.state.message.userMessage}</Text>
                            </View>
                            <View style={[style.row, style.fullWidth, { marginTop: 20 }]}>
                                <UserButton user={{ picture: 'https://raw.githubusercontent.com/crisand/addix/main/maya.png' }} style={{ marginRight: 10 }} size={35} ></UserButton>
                                <Text style={{
                                    fontFamily: 'Jost',
                                    flexGrow: 1,
                                    marginTop: 5,
                                    paddingRight: 20,
                                    fontSize: 18,
                                    color: colors.neutralBlue
                                }}>{this.state.message.assistantMessage}</Text>
                            </View>
                        </View>
                    </ScrollView>


                        : null}
                    {/* <View style={{ flex: 1 }}></View> */}
                    <PrimaryButton align={'right'} onPress={() => {
                        this.context.gotoStore();
                    }} label={`What seems to be the problem? Let's talk!`} style={{
                        width: '100%', padding: 10,
                        marginTop: 10, marginBottom: 10
                    }} />
                </View>
            </View>

        )
    };

}

export default AIMessageScreen;

const styles = StyleSheet.create({});
