import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { User } from "../models";
import { SheetManager } from 'react-native-actions-sheet';
import UserButton from './user-button';

interface State {
    userLevel?: any
}

interface Props {
    hideDetails?: boolean
    user?: User | null
    style?: ViewStyle
}

class UserButtonLong extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    userButton: any;
    constructor(props: any) {
        super(props);
        this.state = {}

    }

    componentDidMount() {
        const userLevel = this.context.getUserLevel(this.props.user?.campaignStart);
        this.setState({ userLevel });
    }

    render() {

        return (
            <View style={[style.row, style.vcenter, this.props.style, {}]}>
                <UserButton ref={ref => this.userButton = ref} user={this.props.user} size={35} />
                <TouchableOpacity onPress={async () => {
                    this.userButton.onPressMe();
                }} style={[{ marginLeft: 10 }]}>
                    <View style={[style.column, { marginLeft: 5 }]}>
                        <Text style={[{ fontSize: 18, fontFamily: 'Jost', fontWeight: 'bold' }]}>{this.props.user?.firstName} {this.props.user?.lastName.substr(0, 1)}.</Text>
                        {this.state.userLevel?.daysSinceQuit !== -1 && !this.props.hideDetails ? <Text style={[{ fontSize: 12, fontFamily: 'Jost' }]}>{this.context.getTextWithFirstLetterCapital(this.props.user?.campaignType)} - {this.state.userLevel?.daysSinceQuit}d - {this.state.userLevel?.levelLabel}</Text> : null}
                    </View>
                </TouchableOpacity>
            </View>
        )
    };

}

const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});

export default UserButtonLong;
