/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      isPremium
      campaignStart
      campaignType
      reports
      deviceToken
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserPostLikes = /* GraphQL */ `
  query GetUserPostLikes($id: ID!) {
    getUserPostLikes(id: $id) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserPostLikes = /* GraphQL */ `
  query ListUserPostLikes(
    $filter: ModelUserPostLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPostLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        post {
          id
          userId
          aiChatId
          imagePreview
          text
          description
          imageKey
          postLink
          postItemType
          ipLikes
          type
          postType
          reports
          numLikes
          numViews
          numComments
          savedAt
          createdAt
          updatedAt
          __typename
        }
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLikesByTimeAndUser = /* GraphQL */ `
  query GetLikesByTimeAndUser(
    $userId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPostLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLikesByTimeAndUser(
      userId: $userId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        post {
          id
          userId
          aiChatId
          imagePreview
          text
          description
          imageKey
          postLink
          postItemType
          ipLikes
          type
          postType
          reports
          numLikes
          numViews
          numComments
          savedAt
          createdAt
          updatedAt
          __typename
        }
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAddixCooment = /* GraphQL */ `
  query GetAddixCooment($id: ID!) {
    getAddixCooment(id: $id) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAddixCooments = /* GraphQL */ `
  query ListAddixCooments(
    $filter: ModelAddixCoomentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddixCooments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommentsByTime = /* GraphQL */ `
  query GetCommentsByTime(
    $postId: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddixCoomentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentsByTime(
      postId: $postId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserMessageThreads = /* GraphQL */ `
  query GetUserMessageThreads($id: ID!) {
    getUserMessageThreads(id: $id) {
      id
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserMessageThreads = /* GraphQL */ `
  query ListUserMessageThreads(
    $filter: ModelUserMessageThreadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMessageThreads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        sender {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        lastMessage
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThreadsBySender = /* GraphQL */ `
  query GetThreadsBySender(
    $senderId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMessageThreadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getThreadsBySender(
      senderId: $senderId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        sender {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        lastMessage
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThreadsByRecipient = /* GraphQL */ `
  query GetThreadsByRecipient(
    $recipientId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMessageThreadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getThreadsByRecipient(
      recipientId: $recipientId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        sender {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        lastMessage
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserMessage = /* GraphQL */ `
  query GetUserMessage($id: ID!) {
    getUserMessage(id: $id) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserMessages = /* GraphQL */ `
  query ListUserMessages(
    $filter: ModelUserMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notificationId
        threadId
        senderId
        sender {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        message
        imageKey
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessagesByThread = /* GraphQL */ `
  query GetMessagesByThread(
    $threadId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagesByThread(
      threadId: $threadId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notificationId
        threadId
        senderId
        sender {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        message
        imageKey
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAddixNotification = /* GraphQL */ `
  query GetAddixNotification($id: ID!) {
    getAddixNotification(id: $id) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAddixNotifications = /* GraphQL */ `
  query ListAddixNotifications(
    $filter: ModelAddixNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddixNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        seen
        notificationCreatedBy
        notificationCreatedByUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        notificationType
        notificationData
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationsByTime = /* GraphQL */ `
  query GetNotificationsByTime(
    $userId: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddixNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByTime(
      userId: $userId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        seen
        notificationCreatedBy
        notificationCreatedByUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        notificationType
        notificationData
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAddixPost = /* GraphQL */ `
  query GetAddixPost($id: ID!) {
    getAddixPost(id: $id) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      aiChatId
      aiChat {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      imagePreview
      text
      description
      imageKey
      postLink
      postItemType
      ipLikes
      type
      postType
      reports
      numLikes
      numViews
      numComments
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAddixPosts = /* GraphQL */ `
  query ListAddixPosts(
    $filter: ModelAddixPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddixPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostsByTimeAndUser = /* GraphQL */ `
  query GetPostsByTimeAndUser(
    $userId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddixPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByTimeAndUser(
      userId: $userId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAllPostsByTime = /* GraphQL */ `
  query GetAllPostsByTime(
    $type: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddixPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAllPostsByTime(
      type: $type
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostsByTime = /* GraphQL */ `
  query GetPostsByTime(
    $postType: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddixPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByTime(
      postType: $postType
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAIChats = /* GraphQL */ `
  query GetAIChats($id: ID!) {
    getAIChats(id: $id) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAIChats = /* GraphQL */ `
  query ListAIChats(
    $filter: ModelAIChatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAIChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAIMessagesByThreadId = /* GraphQL */ `
  query GetAIMessagesByThreadId(
    $threadId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIChatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAIMessagesByThreadId(
      threadId: $threadId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAIMessagesByType = /* GraphQL */ `
  query GetAIMessagesByType(
    $type: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIChatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAIMessagesByType(
      type: $type
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAIThreads = /* GraphQL */ `
  query GetAIThreads($id: ID!) {
    getAIThreads(id: $id) {
      id
      userId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAIThreads = /* GraphQL */ `
  query ListAIThreads(
    $filter: ModelAIThreadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAIThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        message
        threadId
        assistantId
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAIThreadsByUserId = /* GraphQL */ `
  query GetAIThreadsByUserId(
    $userId: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIThreadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAIThreadsByUserId(
      userId: $userId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        message
        threadId
        assistantId
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
