import React, { Component } from "react";
import navigationService from "../services/navigation-service";
import { Alert, AppState, AsyncStorage, BackHandler, Dimensions, Linking, Platform, Share } from "react-native";

import { InAppBrowser } from "react-native-inappbrowser-reborn";
import { Amplify, API, Auth, graphqlOperation } from "aws-amplify";
import config from "./../aws-exports";
import * as queries from "../graphql/queries";
import publicIP from 'react-native-public-ip';
const CryptoJS = require('crypto-js');
import messaging from '@react-native-firebase/messaging';
const AWS = require('aws-sdk');
import Lambda from 'aws-sdk/clients/lambda';
import {
  createUser,
  deleteUser,
  updateUser,
  createAddixPost,
  createAddixNotification,
  createAddixCooment,
  updateAddixPost,
  createUserPostLikes,
  updateAddixNotification,
  deleteAddixPost,
  updateAddixCooment,
  createAIThreads,
  deleteAIThreads,
  createAIChats
} from "./../graphql/mutations";
import {
  onCreateAddixNotification
} from "./../graphql/subscriptions";

import DeviceInfo from "react-native-device-info";
import { SheetManager } from "react-native-actions-sheet";
import InAppReview from 'react-native-in-app-review';
import { Storage } from "aws-amplify";
import colors from "../constants/colors";
import analytics from "@react-native-firebase/analytics";
import ReactGA from "react-ga4";
///@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { TumblrShareButton } from "react-share";
import { AddixCooment, AddixPost, User } from "../models";
export interface IAppState {
  generatedActions?: number;
  initialLinkToShare?: any;
  thread?: any,
  currentThread?: any,
  imagePreview?: string;
  user?: User;
  selectedUser?: User;
  viewPostComments?: AddixPost;
  hasUnseenNotifications?: boolean;
  showStats?: boolean;
  sharePost?: any
  deviceId: string;
  userPrompt?: string;
  adminMessage?: string;
  publicIp?: string;
  requestNonPersonalizedAdsOnly: boolean,
  appMenuOpened?: boolean;
  appMenuOpenedBack?: boolean;
  packageName?: any;
  checkedStore?: boolean
  adData?: any
  isAdmin?: boolean;
  os?: string
  shareUrl?: string,
  hasShownPolicy: string;
  reportUser: any;
  routeParams: any,
  shareModalVisible?: boolean,
  appList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  myPostsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  myLikedList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  messageThreads: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  messageList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  notificationsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  commentsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  chatsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  historyList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  statsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  isBusy?: boolean
  appConfig?: any
  screen?: any
  isPremium: boolean;
  isMobile: boolean;
  isTablet: boolean;
  userPicture: string;
  isPortrait: boolean;
}

export interface IProps {
  Toast: any
}

export interface IContext {
  state: IAppState,
  hideMenu: () => void,
  signOut: () => void,
  gotoStore: () => void,
  deleteThread: () => void,
  clearChat: () => void,
  postCampaign: (mess?: string) => void,
  logEvent: (name: string) => void
  getUnseenNotifications: () => void
  getChatMessage: (force?: boolean) => void,
  gotoChatHistory: (threadItem: any) => void,
  showDeleteThread: (threadId: string) => void,
  playRewardedAndGo: () => void,
  banUser: () => void,
  getTextWithFirstLetterCapital: (text: string) => string,
  getUserLevel: (dateString: string, endDate?: string) => any,
  sendMail: (emailTo: string, subject: string, message: string) => void,
  appReview: () => void,
  waitSomeSeconds: (time: number) => void,
  gotoNotification: (data: any) => void,
  likePost: (post: AddixPost) => void,
  reportPost: (post: AddixPost) => void,
  deletePost: (post: AddixPost) => void,
  reportUserAccount: (isBlocked?: boolean) => void,
  reportComment: (comment: AddixCooment) => void,
  postComment: (post: AddixPost, comment: string, replyToUser?: any) => void,
  startQuittingCampaign: (type: any) => void,
  postText: (text: string, type: any) => void,
  postAIChat: (aiChat: any, type: any) => void,
  postLink: (metatags: any, link: string, text: string, type: any) => void,
  postPhoto: (imageKey: any, text: string, type: any) => void,
  sendPushNotification: (title: string, message: any, data: any, deviceToken: string) => void,
  playRewardedAdAndGenerate: (callback?: any) => void,
  buyPremiumSubscription: (isYearly?: boolean) => void,
  restorePurchase: () => void,
  showRewarded: (callback?: any) => void,
  updateAccount: (data: any) => void,
  shareMe: (data: any) => void,
  getListItems: (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => void,
  setScreen: (screen: string, navigate?: boolean, params?: any, callback?: any) => void
  setAppProp: (obj: any, callback?: any) => void,
  showToast: (message: string, type: 'error' | 'success') => void,
  setUser: (user: any) => void
}

const initialState: any = {
  requestNonPersonalizedAdsOnly: false,
  appList: { items: [], loading: false, refreshing: false },
  myPostsList: { items: [], loading: false, refreshing: false },
  chatsList: { items: [], loading: false, refreshing: false },
  myLikedList: { items: [], loading: false, refreshing: false },
  messageThreads: { items: [], loading: false, refreshing: false },
  messageList: { items: [], loading: false, refreshing: false, sortKey: 'savedAt' },
  notificationsList: { items: [], loading: false, refreshing: false },
  commentsList: { items: [], loading: false, refreshing: false },
  historyList: { items: [], loading: false, refreshing: false },
  statsList: { items: [], loading: false, refreshing: false },
  appConfig: {},
  isBusy: false
}

export const AppContext = React.createContext<IContext>(initialState);
export const AppContextProvider = AppContext.Provider

export class AppProvider extends Component<IProps, any> {
  state = initialState
  isSettingUser: boolean = false;
  tmAd: any
  firstScreen: any
  toDeleteThreadId: any;
  goRewardedCallback: any;
  doNotPlayAd: boolean = false;
  tm?: any
  private canPlay: boolean = false;
  showedOnceCampaign: boolean = false;

  constructor(props: IProps) {
    super(props);

  }



  waitSomeSeconds = (time: number) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  }
  gotoStore() {
    if (this.state.os === 'ios') {
      //window.open(this.state.appConfig.iOSApp)
      Linking.openURL(this.state.appConfig.iOSApp);

    } else {
      //window.open(this.state.appConfig.androidApp)
      Linking.openURL(this.state.appConfig.androidApp);
    }
  }
  deleteThread = async () => {
    if (this.toDeleteThreadId) {
      await API.graphql(graphqlOperation(deleteAIThreads, { input: { id: this.toDeleteThreadId } }))
      this.toDeleteThreadId = undefined;
    }
  }
  showDeleteThread = async (threadId: any) => {
    this.toDeleteThreadId = threadId;
    SheetManager.show("delete_thread");
  }
  gotoChatHistory = async (threadItem: any) => {
    this.state.currentThread = threadItem;
    this.state.thread = threadItem.threadId;
    this.setScreen("AIHome", true);
    let history = (await API.graphql({
      query: queries.getAIMessagesByThreadId,
      variables: { threadId: this.state.currentThread.id, limit: 100, sortDirection: 'ASC' },
    }) as any).data.getAIMessagesByThreadId;
    let historyItems = history.items;
    let chatsList = { nextToken: "", items: [], loading: false, refreshing: false }
    chatsList.items = historyItems.map((item: any) => {
      return { user: item.userMessage, ai: item.assistantMessage, id: item.id }
    });
    this.setState({ chatsList, thread: threadItem.threadId, currentThread: threadItem })
    //console.log("historyItems", historyItems)
  }
  clearChat = async () => {
    let chatsList = { nextToken: "", items: [], loading: false, refreshing: false }
    this.setState({ chatsList, thread: "", currentThread: undefined })
  }
  getChatMessage = async (force?: boolean) => {
    let generatedActions;
    /* if (Platform.OS !== "web") {
      generatedActions = parseInt(await AsyncStorage.getItem("generatedActions") || "0") || 0;
    } else {
      generatedActions = 0;
    } */
    /* if (!force && generatedActions % this.state.adData?.genBetween === 0 && generatedActions >= this.state.adData?.maxGen && !this.state.isPremium && this.state.adData?.enabled && this.state.checkedStore && Platform.OS !== 'web') {

      this.showRewarded(this.getChatMessage.bind(this, true))
      return;
    } */
    this.setState({ isBusy: true })
    if (!this.state.thread) {
      try {
        const result: any = await this.getAIMessage({ op: "init-thread" });
        const thread = result.outputResult.threadId;
        const assistantId = result.outputResult.assistantId;
        //console.log("thread", thread)
        let newThread: any = {
          savedAt: new Date().toISOString(),
          threadId: thread,
          assistantId: assistantId,
          userId: this.state.user.id,
          message: this.state.userPrompt
        };
        const createThreadsResult = await API.graphql(graphqlOperation(createAIThreads, { input: newThread })) as any;
        let currentThread: any = createThreadsResult.data.createAIThreads
        this.state.thread = thread;
        this.state.currentThread = currentThread;
        this.setState({ thread, currentThread })
        this.logEvent("new_chat")
      } catch (e) {
        this.setState({ isBusy: false })
        this.showToast("Something went wrong, please try again!", "info")
        return e;
      }
    }

    //console.log("getAIMessage", this.state.userPrompt, this.state.thread)
    let message: any = {};
    message.user = this.state.userPrompt;
    message.ai = "";
    message.id = uuidv4();
    const chatsList = { ...this.state.chatsList };
    const chatsListItems = this.state.chatsList.items.concat(message);
    chatsList.items = chatsListItems;
    //console.log("chatsList", chatsList)
    this.setState({ chatsList: chatsList });

    let aiMessage: any
    try {
      aiMessage = await this.getAIMessage({ op: "message", threadId: this.state.thread, message: this.state.userPrompt });
      const ionMessage = aiMessage.outputResult?.content[0].text.value;
      if (!ionMessage) {
        this.setState({ isBusy: false })
        return;
      }
      message.ai = ionMessage;
      //refresh the state
      chatsListItems[chatsListItems.length - 1] = message;
      chatsList.items = chatsListItems;
      this.setState({ chatsList: chatsList });
      //console.log("aiMessage", aiMessage)


      let newChat = {
        userId: this.state.user.id,
        threadId: this.state.currentThread.id,
        assistantId: this.state.currentThread.assistantId,
        assistantMessage: ionMessage,
        userMessage: this.state.userPrompt,
        type: "user",
        savedAt: new Date().toISOString()
      }
      const chatMessage = (await API.graphql(graphqlOperation(createAIChats, { input: newChat })) as any).data.createAIChats;
      message.id = chatMessage.id;
      this.setState({ isBusy: false, userPrompt: "" })
    } catch (e) {
      //remove the last message
      chatsListItems.pop();
      chatsList.items = chatsListItems;
      this.setState({ isBusy: false, chatsList })
      this.showToast("Something went wrong, please try again!", "info")
      return e;
    }

    /* if (Platform.OS !== "web") {
      generatedActions++;
      AsyncStorage.setItem("generatedActions", generatedActions.toString());
      const hasReview = await AsyncStorage.getItem(this.state.appConfig.reviewKey || "hasReview");
      if (!hasReview && generatedActions >= this.state.appConfig.reviewAfterMessagesNumber) {
        AsyncStorage.setItem(this.state.appConfig.reviewKey || "hasReview", "true");
        SheetManager.show('app_review');
      }

    } else {
      generatedActions = 0;
    } */

  }
  async getAIMessage(body: any) {

    return new Promise(async (resolve, reject) => {
      /* const isTest = true;
      if (isTest) { */
      /* config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002/"
      Amplify.configure(config); */
      Amplify.API.post('addixrestapi', `/ai/`, { body })
        .then(function (result: any) {
          //console.log("result", result);
          resolve(result);
        })
        .catch(function (error: any) {
          console.log("error", error);
          reject(error);
        });

    })

  }
  postText = async (text: string, data: any) => {
    if (text) {
      await API.graphql(graphqlOperation(createAddixPost, {
        input: {
          userId: this.state.user.id,
          text: text,
          postItemType: "text",
          ipLikes: [],
          type: "public",
          postType: data.type,
          reports: [],
          numLikes: 0,
          numViews: 0,
          numComments: 0,
          savedAt: new Date().toISOString()
        }
      }));
    }

  }
  deleteUser = async () => {
    await API.graphql(graphqlOperation(deleteUser, { input: { id: this.state.user?.id } }))
    this.showToast("Your account has been deleted", "success");
    this.signOut();
    this.setScreen("Home");
  }
  reportComment = async (comment: AddixCooment) => {
    const userId = this.state.user ? this.state.user.id : null;
    try {
      const reports = comment.reports || [];
      if (!reports.includes(userId)) {
        reports.push(userId);
        const toSaveComment = {
          id: comment.id,
          reports
        }
        await API.graphql(graphqlOperation(updateAddixCooment, { input: toSaveComment }))
        this.showToast("Reported", "success");
      } else {
        this.showToast("Already reported", "error");
      }
    } catch (e) {

    }

  }
  postComment = async (post: AddixPost, comment: string, replyToUser?: any) => {
    if (comment) {

      await API.graphql(graphqlOperation(createAddixCooment, {
        input: {
          userId: this.state.user.id,
          postId: post.id,
          replyToUserId: replyToUser ? replyToUser.id : null,
          reports: [],
          text: comment,
          savedAt: new Date().toISOString()
        }
      }))
      API.graphql(graphqlOperation(updateAddixPost, {
        input: {
          id: post.id,
          numComments: (post.numComments || 0) + 1
        }
      }));
      if (post.userId !== this.state.user.id) {
        const notification = (await API.graphql(graphqlOperation(createAddixNotification, {
          input: {
            userId: post.userId,
            seen: false,
            notificationType: "comment",
            notificationCreatedBy: this.state.user.id,
            notificationData: JSON.stringify({
              postId: post.id,
              comment: comment
            }),
            savedAt: new Date().toISOString()
          }
        })) as any).data.createAddixNotification;
        if (post.user.deviceToken) {
          this.sendPushNotification("New Comment", `${this.state.user.firstName} commented on your post: "${comment.substr(0, 20)}..."`, { postId: post.id, notificationType: "comment", notId: notification.id }, post.user.deviceToken)
        }
      }
      if (replyToUser) {
        const replyNotification = (await API.graphql(graphqlOperation(createAddixNotification, {
          input: {
            userId: replyToUser.id,
            seen: false,
            notificationType: "comment-reply",
            notificationCreatedBy: this.state.user.id,
            notificationData: JSON.stringify({
              postId: post.id,
              comment: comment
            }),
            savedAt: new Date().toISOString()
          }
        })) as any).data.createAddixNotification;
        if (replyToUser.deviceToken) {
          this.sendPushNotification("Comment Reply", `${this.state.user.firstName} replied to your comment: "${comment.substr(0, 20)}..."`, { postId: post.id, notificationType: "comment-reply", notId: replyNotification.id }, replyToUser.deviceToken)
        }
      }
    }

  }
  likePost = async (post: AddixPost) => {

    if (!this.state.user) {
      this.setScreen("Sign In", true);
      return;
    }
    const postuserid = `${this.state.user.id}${post.id}`
    const liked = (await API.graphql({
      query: queries.getUserPostLikes,
      variables: { id: postuserid }
    }) as any).data.getUserPostLikes;
    //console.log("liked", liked)
    if (liked) {
      //error({ message: "Already liked" });
      this.showToast("Already liked", "error")
      return post;
    }
    this.showToast("Liked", "success");
    //this.sendPushNotification(lyriPost.user, lyriPost.id);
    const like = {
      id: postuserid,
      userId: this.state.user.id,
      postId: post.id,
      savedAt: new Date().toISOString()
    }
    try {

      (await API.graphql(graphqlOperation(createUserPostLikes, { input: like })) as any).data.createUserPostLikes;
      const numLikes = (post.numLikes || 0) + 1;
      const toSavePost = {
        id: post.id,
        numLikes
      }
      const savedPost = (await API.graphql(graphqlOperation(updateAddixPost, { input: toSavePost })) as any).data.updateAddixPost;
      const appList = { ...this.state.appList }
      const index = appList.items.findIndex((item: any) => item.id === savedPost.id);
      if (index !== -1) {
        appList.items[index].numLikes = savedPost.numLikes;
        this.setState({ appList });
      }

      if (post.userId !== this.state.user.id) {
        const notification = (await API.graphql(graphqlOperation(createAddixNotification, {
          input: {
            userId: post.userId,
            seen: false,
            notificationType: "like",
            notificationCreatedBy: this.state.user.id,
            notificationData: JSON.stringify({
              postId: post.id
            }),
            savedAt: new Date().toISOString()
          }
        })) as any).data.createAddixNotification;
        if (post.user.deviceToken) {
          this.sendPushNotification("Post Like", `${this.state.user.firstName} like your post`, { postId: post.id, notificationType: "like", notId: notification.id }, post.user.deviceToken)
        }
      }

      /*  if (post.user.id) {
         const toSaveUser = {
           id: post.user.id,
           userLikes: (post.user.userLikes || 0) + 1
         }
         await API.graphql(graphqlOperation(updateUser, { input: toSaveUser }));
       } */
      return savedPost;
    } catch (err) {
      console.log("error creating like", err)
    }

  }
  async seenNotificationById(id: any) {
    await API.graphql(graphqlOperation(updateAddixNotification, {
      input: {
        id: id,
        seen: true
      }
    }));
    this.getUnseenNotifications();
  }
  postLink = async (metatags: any, link: string, text: string, data: any) => {

    await API.graphql(graphqlOperation(createAddixPost, {
      input: {
        userId: this.state.user.id,
        text: text,
        postItemType: "link",
        postLink: link,
        imagePreview: metatags.image,
        description: metatags.description,
        ipLikes: [],
        type: "public",
        postType: data.type,
        reports: [],
        numLikes: 0,
        numViews: 0,
        numComments: 0,
        savedAt: new Date().toISOString()
      }
    }));


  }
  postAIChat = async (aiChat: any, data: any) => {
    await API.graphql(graphqlOperation(createAddixPost, {
      input: {
        userId: this.state.user.id,
        text: "",
        postItemType: "ai-message",
        aiChatId: aiChat.id,
        ipLikes: [],
        type: "public",
        postType: data.type,
        reports: [],
        numLikes: 0,
        numViews: 0,
        numComments: 0,
        savedAt: new Date().toISOString()
      }
    }));
  }
  postCampaign = async (mess?: string) => {
    await API.graphql(graphqlOperation(createAddixPost, {
      input: {
        userId: this.state.user.id,
        text: mess,
        postItemType: "campaign",
        ipLikes: [],
        type: "public",
        postType: this.state.user.campaignType,
        reports: [],
        numLikes: 0,
        numViews: 0,
        numComments: 0,
        savedAt: new Date().toISOString()
      }
    }));
  }
  postPhoto = async (imageKey: string, text: string, data: any) => {

    await API.graphql(graphqlOperation(createAddixPost, {
      input: {
        userId: this.state.user.id,
        text: text,
        postItemType: "image",
        ipLikes: [],
        imageKey: imageKey,
        type: "public",
        postType: data.type,
        reports: [],
        numLikes: 0,
        numViews: 0,
        numComments: 0,
        savedAt: new Date().toISOString()
      }
    }));


  }
  getUserLevel = (dateString: string, endDate?: string) => {
    if (!dateString) return { daysSinceQuit: -1, levelLabel: 'None', levelColor: '#000000' };
    const calculateTimeSinceQuit = (quitDate: Date) => {
      const now = endDate ? new Date(endDate) : new Date();
      const diffInMilliseconds = now.getTime() - quitDate.getTime();

      const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diffInMilliseconds / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diffInMilliseconds / (1000 * 60)) % 60);

      return { days, hours, minutes };
    }

    const { days: daysSinceQuit, hours: hoursSinceQuit, minutes: minutesSinceQuit } = calculateTimeSinceQuit(new Date(dateString));
    /* let daysSinceQuit = Math.floor((new Date().getTime() - new Date(dateString).getTime()) / (1000 * 60 * 60 * 24));
    //the remaining hours and minutes
    let hoursSinceQuit = Math.floor((new Date().getTime() - new Date(dateString).getTime()) / (1000 * 60 * 60)) - daysSinceQuit * 24;
    let minutesSinceQuit = Math.floor((new Date().getTime() - new Date(dateString).getTime()) / (1000 * 60)) - daysSinceQuit * 24 * 60 - hoursSinceQuit * 60; */
    //console.log('daysSinceQuit', daysSinceQuit);
    //console.log('hoursSinceQuit', new Date(dateString));
    //daysSinceQuit = 1;
    let levelLabel;
    let levelColor;
    if (daysSinceQuit <= 7) {
      levelLabel = "Beginner"
      levelColor = "#ff0000";
      return { daysSinceQuit, hoursSinceQuit, minutesSinceQuit, levelLabel, levelColor };
    }
    if (daysSinceQuit <= 30) {
      levelLabel = "Intermediate"
      levelColor = "#ff9900";
      return { daysSinceQuit, hoursSinceQuit, minutesSinceQuit, levelLabel, levelColor };
    };
    if (daysSinceQuit <= 90) {
      levelLabel = "Advanced"
      levelColor = "#ffcc00";
      return { daysSinceQuit, hoursSinceQuit, minutesSinceQuit, levelLabel, levelColor };
    }
    if (daysSinceQuit <= 365) {
      levelLabel = "Expert"
      levelColor = "#00cc00";
      return { daysSinceQuit, hoursSinceQuit, minutesSinceQuit, levelLabel, levelColor };
    };
    if (daysSinceQuit >= 365 * 10) {
      levelLabel = "Master"
      levelColor = "#0000ff";
      return { daysSinceQuit, hoursSinceQuit, minutesSinceQuit, levelLabel, levelColor };
    };

  }
  getTextWithFirstLetterCapital = (text: string) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  sendNewsletter = async () => {

    config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002/"
    Amplify.configure(config)
    const message = `
       `;
    const result = await API.post('lyritunesrest', '/rest/send-blast-email', {
      body: { subject: "🎉 LyriTunes - Updating and Upgrading", message, isTest: true, templateId: "LyriTunesTemplate3" }
    })
    console.log("result", result)
  }


  async componentDidMount() {
    //console.log("deviceId", deviceId)
    //get initial shared link

    const ip = await publicIP();
    this.setState({ publicIp: ip });
    if (Platform.OS === 'web') {
      window.addEventListener("resize", this.checkOrientation.bind(this));
      this.checkOrientation();
    } else {
      const authStatus = await messaging().requestPermission();
      const enabled =
        authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
        authStatus === messaging.AuthorizationStatus.PROVISIONAL;
      console.log("fcm enabled", enabled)
      if (enabled) {

      }
      try {
        messaging().setBackgroundMessageHandler(async remoteMessage => {
          console.log('Message handled in the background!', remoteMessage);
          /* if (remoteMessage?.data?.postId) {
            this.setScreen('View', true, { id: remoteMessage?.data?.postId })
          }
          switch (remoteMessage?.data?.type) {
            case "comment":
              this.setScreen('View', true, { id: remoteMessage?.data?.postId })
              break;
          } */
          this.gotoNotification(remoteMessage.data);
          //this.sendPushNotification("New Comment", this.state.user.firstName + " commented on your post " + comment.substr(0, 30) + "...", { postId: post.id, type: "comment" }, post.user.deviceToken)
          // Handle the message here, e.g., show a notification or update the app's UI
        });
      } catch (e) {
        console.log("error", e)
      }
      if (Platform.OS === 'android') {
        this.listenBackButton();
      }
      Dimensions.addEventListener('change', () => {
        this.checkOrientationMobile();
      });
      this.checkOrientationMobile();
    }
    this.getAppConfig();

  }
  gotoNotification = async (data: any) => {
    //console.log("kaka", data)
    switch (data.notificationType) {
      case 'admin':
        this.setState({ adminMessage: data.message }, () => {
          SheetManager.show('admin_message_sheet');
        });
        break;
      case 'comment':
        const post = (await API.graphql({ query: queries.getAddixPost, variables: { id: data.postId } }) as any).data.getAddixPost;
        //console.log("post", post)
        if (post) {
          await this.waitSomeSeconds(100);
          this.setAppProp({
            viewPostComments: post
          }, () => {
            SheetManager.show('comments-box');
          })
        }
        break;
      case 'comment-reply':
        const replyPost = (await API.graphql({ query: queries.getAddixPost, variables: { id: data.postId } }) as any).data.getAddixPost;
        //console.log("replyPost", replyPost)
        if (replyPost) {
          await this.waitSomeSeconds(100);
          this.setAppProp({
            viewPostComments: replyPost
          }, () => {
            SheetManager.show('comments-box');
          })
        }
        break;
      case 'like':
        const postLike = (await API.graphql({ query: queries.getAddixPost, variables: { id: data.postId } }) as any).data.getAddixPost;
        //console.log("postLike", postLike)
        if (postLike) {
          await this.waitSomeSeconds(100);
          this.setAppProp({
            viewPostComments: postLike
          }, () => {
            SheetManager.show('comments-box');
          })
        }
        break;
      case 'message':
        try {
          if (!data.notificationCreatedByUser) {
            data.notificationCreatedByUser = (await API.graphql({ query: queries.getUser, variables: { id: data.userId } }) as any).data.getUser;
          }
          if (data.notificationCreatedByUser) {
            this.setAppProp({
              selectedUser: data.notificationCreatedByUser
            }, () => {
              this.setScreen('Message', true);
            })

          }
        } catch (e) {
        }
        break;
    }
    this.seenNotificationById(data.notId);

  }
  sendPushNotification = async (title: string, message: any, data: any, deviceToken: string) => {
    //console.log("user", user)
    if (deviceToken) {
      //console.log("user.devices", user?.deviceToken)
      try {
        const result = (await API.post('addixrestapi', '/rest/send-push-notification/', {
          body: {
            device: deviceToken,
            title,
            data,
            message
          }
        }));
        console.log("result", JSON.stringify(result));
      } catch (e) {
        console.log("error", e)
      }
    }
  }
  getAd = (addType: string, callback?: any) => {
    let ad;
    console.log("getAd", addType, this.state.adData[addType])
    switch (addType) {
      case "re":
        ad = navigationService.props.RewardedAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "in":
        ad = navigationService.props.InterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "inre":
        ad = navigationService.props.RewardedInterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "op":
        ad = navigationService.props.AppOpenAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
    }
    return ad;
  }
  createAd = (addType: string, callback?: any, onClose?: any) => {
    let ad: any;

    if (Platform.OS === "web" || this.state.isPremium) return;
    if (addType === 'open') {
      ad = this.getAd(this.state.adData['openType']);
    } else {
      ad = this.getAd(addType);
    }
    //console.log("createAd", addType, ad)

    try {
      ad.addAdEventListener('rewarded_loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true, receivedReward: false })
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true });
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('closed', () => {

        this.setPlayAd(false)
        this.setState({ isAdPlaying: false })
        if (onClose && addType === "in") {
          onClose()
        };

      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('error', (e: any) => {
        console.log("error", e)
        this.setPlayAd(false)
        this.setState({ isAdPlaying: false, receivedReward: true }, () => {
          if (onClose) onClose();
        })


      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('rewarded_earned_reward', () => {
        this.setPlayAd(false)
        this.setState({ isAdPlaying: false, receivedReward: true }, () => {
          if (onClose) onClose();
        })

      });
    } catch (e) {
    }
    ad.load();
    return ad;
  }
  setPlayAd(doNot?: boolean) {
    if (doNot) {
      this.doNotPlayAd = true;
    } else {
      setTimeout(() => {
        this.doNotPlayAd = false;
      }, 3000);
    }

  }
  startQuittingCampaign = async (data: any) => {
    //console.log("data", data)
    const acc = (await API.graphql(graphqlOperation(updateUser, {
      input: {
        id: this.state.user.id,
        campaignStart: new Date().toISOString(),
        campaignType: data.type
      }
    })) as any).data.updateUser;
    this.setState({ user: acc });
  }
  showRewarded = (callback?: any) => {
    this.goRewardedCallback = callback;
    SheetManager.show('go_ad_view');
  }
  playRewardedAndGo = () => {
    this.playRewardedAdAndGenerate(this.goRewardedCallback);
  }
  playRewardedAdAndGenerate = (callback?: any) => {
    this.createAd('re', (ad: any) => {
      ad.show();
    }, () => {
      if (this.state.receivedReward) {
        callback();

      } else {
        this.showToast("You did not get a reward, please wait until the ad finishes playing and the reward is received!", "error")
      }
    })
  }
  isPortrait = () => {
    const dim = Dimensions.get('window');
    return dim.height >= dim.width;
  };

  listenBackButton = () => {
    const me = this;
    const backHandler = BackHandler.addEventListener('hardwareBackPress', function () {
      console.log("back pressed", me.state.screen)
      if (me.state.viewPost) {

        return true;
      } else if (me.state.appMenuOpened) {
        me.hideMenu();
        backHandler.remove();
        me.listenBackButton();
        return true;
      } else if (me.state.imagePreview) {
        me.setState({ imagePreview: null });
        backHandler.remove();
        me.listenBackButton();
        return true;
      } else {
        if ((me.state.screen === 'Home' || !me.state.screen)) {
          //throw {};
          backHandler.remove();
          me.listenBackButton();
          BackHandler.exitApp();
          return true;
        }
      }
      if (!navigationService.props.setHome && !me.state.viewPost && !me.state.appMenuOpened) {
        //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen('Home', {}, false));
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
        backHandler.remove();
        me.listenBackButton();
        return true;
      }



      return false;
    });
  }


  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  deletePost = async (post: AddixPost) => {
    try {
      this.setState({ isBusy: true });
      const result = (await API.graphql(graphqlOperation(deleteAddixPost, { input: { id: post.id } })) as any).data.deleteAddixPost;
      //update the history state
      const appList = { ...this.state.appList }
      const index = appList.items.findIndex((item: any) => item.id === result.id);
      if (index !== -1) {
        appList.items.splice(index, 1);
        this.setState({ appList });
      }
      const myPostsList = { ...this.state.myPostsList }
      const index2 = myPostsList.items.findIndex((item: any) => item.id === result.id);
      if (index2 !== -1) {
        myPostsList.items.splice(index2, 1);
        this.setState({ myPostsList });
      }
      this.showToast("Deleted post!", "success");
      this.setState({ isBusy: false });
    } catch (e) {

      //this.showToast("Already reported", "error");
    }

  }

  reportPost = async (post: AddixPost) => {
    const userId = this.state.user ? this.state.user.id : null;
    try {
      const reports = post.reports || [];
      if (!reports.includes(userId)) {
        reports.push(userId);
        const toSavePost = {
          id: post.id,
          reports
        }
        const savedMeme = (await API.graphql(graphqlOperation(updateAddixPost, { input: toSavePost })) as any).data.updateAddixPost;
        this.showToast("Reported", "success");
      } else {

        this.showToast("Already reported", "error");
      }
    } catch (e) {

    }
  }

  getListItems = async (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => {
    const obj = { ...this.state[stateKey] }
    if (!refresh && !obj.nextToken) return;
    obj.loading = !obj.items.length;
    obj.refreshing = refresh && obj.items.length !== 0;
    if (refresh) {
      obj.items = [];
    }
    this.setState({ [stateKey]: { ...obj } });
    let items = refresh ? await fn() : await fn(obj.nextToken);
    obj.items = refresh ? items.items : obj.items.concat(items.items)
    //make sure obj.items contains unique objects with videoId
    obj.items = obj.items.filter((item: any) => {
      const postReports = item.reports?.length || 0;
      const userReports = item.user?.reports?.length || 0;
      const isBlockedByMe = item.user?.reports?.includes(this.state.user?.id);
      return postReports < 10 && userReports < 10 && !isBlockedByMe;
    })
    if (obj.sortKey) {
      obj.items = obj.items.sort((a: any, b: any) => {
        return new Date(a[obj.sortKey]).getTime() - new Date(b[obj.sortKey]).getTime();
      });
    }
    obj.nextToken = items[nextTokenName]
    obj.loading = false;
    obj.refreshing = false;
    this.setState({ [stateKey]: obj });
  }

  checkOrientationMobile() {
    const orientation = this.isPortrait() ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      isMobile: true,
      isTablet: DeviceInfo.isTablet(),
      isPortrait: orientation === "portrait"
    });
  }



  checkOrientation() {
    let os;
    if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
      os = "android";
    } else if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      os = "ios";
      console.log("IOS")
    } else {
      os = "kaka";
    }
    const orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      os,
      isMobile: this.detectMob(),
      isTablet: window.innerWidth > 1024,
      isPortrait: orientation === 'portrait'
    });

  }

  shareMe = async (data: any) => {
    const url = data.url;
    console.log("url", url)
    if (Platform.OS === "web") {
      if (!this.state.isMobile) {
        this.setState({ shareModalVisible: true, shareUrl: url, sharePost: data })
      } else {
        navigator.share({ url });
      }

    } else {
      await Share.share({
        title: data.title,
        message: url,
        url: url
      });
    }
    this.logEvent("share_message")

  }
  openai = async (prompt: string, max_tokens: number): Promise<any> => {
    const credentials = await Auth.currentCredentials()
    AWS.config.update({
      region: 'us-east-1',
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey
    });
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials)
    });
    return new Promise((resolve, reject) => {
      lambda.invoke({
        FunctionName: 'lyritunesopenai-prod',
        Payload: JSON.stringify({ prompt, max_tokens }),
      }, (err, data: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(JSON.parse(data.Payload).body.text);
        }
      });
    })
  }


  getAppConfig = async () => {
    let appConfig = (await API.get('addixrestapi', '/rest/config', {})).config;
    const bytes = CryptoJS.AES.decrypt(appConfig, "kXp2s5v8y/B?E(G+KbPeShVmYq3t6w9z369kaka");
    appConfig = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //console.log("appConfig", appConfig)
    let adData = Platform.OS !== 'web' ? appConfig.adds[Platform.OS.toString()] : {};
    //console.log("adData", adData)
    let hasShownPolicy = Platform.OS !== "web" ? (await AsyncStorage.getItem("hasShownPolicy2")) : await localStorage.getItem("hasShownPolicy2");
    hasShownPolicy = hasShownPolicy ? 'true' : 'false';
    this.setState({ appConfig, adData }, () => {
      this.initStore();
    });
    if (this.state.screen !== "View") {
      setTimeout(() => {
        this.setState({ hasShownPolicy });
      }, 500);
    }

    //console.log("appConfi", appConfig)
    if (Platform.OS !== 'web') {
      let version = DeviceInfo.getVersion();
      //construct the build number based on version string like for example 0.1.0 will be 10
      let iosBuildNumber = parseInt(version.split(".").join(""));
      let buildNumber = Platform.OS === 'android' ? parseFloat(DeviceInfo.getBuildNumber()) : iosBuildNumber;
      const upVersion = appConfig.versions[Platform.OS];
      if (upVersion > buildNumber) {
        this.showUpdateAlert(appConfig);
      }
      const dateInstalled = await AsyncStorage.getItem("dateInstalled");
      if (!dateInstalled) {
        await AsyncStorage.setItem("dateInstalled", new Date().toISOString());
      }
      //console.log("upVersion", buildNumber, upVersion)
    }
  }
  initStore = async () => {

    if (Platform.OS === "web") {
      this.setState({ checkedStore: true });
      return;
    }
    let isPremium = false;
    const prodSubId = Platform.OS === "android" ? "com.can.addixsub:addixsubplan" : "com.can.addixsub";
    await navigationService.props.adapty.activate('public_live_WLzCXcqm.bna9ywTTWfVeubz2OkV1');
    try {
      const info = await navigationService.props.adapty.getProfile();
      console.log("subscription info", JSON.stringify(info))
      if ((info?.subscriptions?.[prodSubId]?.isActive && !info?.subscriptions?.[prodSubId]?.isRefund)) {
        isPremium = true;
        console.log("premium")
      }
    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription, "error")
    }

    //isPremium = true;

    this.setState({ checkedStore: true, isPremium: isPremium })

    if (!isPremium) {
      if (!navigationService.props.deniedTrackingTransparency) {
        const {
          consentStatus,
          isConsentFormAvailable,
          isRequestLocationInEeaOrUnknown,
        } = await navigationService.props.UMP.requestConsentInfoUpdate()
        if (
          isRequestLocationInEeaOrUnknown &&
          isConsentFormAvailable &&
          consentStatus === (navigationService.props.UMP.CONSENT_STATUS.REQUIRED)
        ) {
          const { consentStatus } = await navigationService.props.UMP.showConsentForm()
          AsyncStorage.setItem("consentStatus", consentStatus.toString());
        }
        const consentStatus2 = await AsyncStorage.getItem("consentStatus");
        if (consentStatus2 !== "3" && consentStatus2) {
          this.setState({ requestNonPersonalizedAdsOnly: true })
        }
      } else {
        this.setState({ requestNonPersonalizedAdsOnly: true })
      }


      let generatedActions = parseInt(await AsyncStorage.getItem("generatedActions") || "0") || 0;
      if (generatedActions >= this.state.adData.maxGen) {

        if (this.state.adData.hasIntroAd2) {


        }
      }

    }
    AppState.addEventListener("change", async (nextAppState: any) => {
      if (nextAppState === "active") {
        this.getUnseenNotifications();
      }

    });

  }


  buyPremiumSubscription = async (isYearly?: boolean) => {
    console.log("buyPremium")
    try {
      let packName = "";
      if (!isYearly) {
        packName = Platform.OS === "android" ? "com.can.addixsub" : "com.can.addixsub";

      } else {
        packName = Platform.OS === "android" ? "com.can.addixsub" : "com.can.addixsub";
      }
      const paywall = await navigationService.props.adapty.getPaywall('PlacementID');
      const products = await navigationService.props.adapty.getPaywallProducts(paywall);
      console.log("products", products)
      const adaptyProduct = products.find((p: any) => p.vendorProductId === packName) || products[0];
      const {
        receipt,
        purchaserInfo,
        product
      }: any = await navigationService.props.adapty.makePurchase(adaptyProduct);
      console.log("receipt", receipt);
      console.log("purchaserInfo", JSON.stringify(purchaserInfo));
      console.log("product", JSON.stringify(product));
      const info = await navigationService.props.adapty.getProfile();

      console.log("info", JSON.stringify(info))
      const checkPackName = Platform.OS === "android" ? "com.can.addixsub:addixsubplan" : "com.can.addixsub";
      if (info?.subscriptions?.[checkPackName]?.isActive && !info?.subscriptions?.[checkPackName]?.isRefund) {
        this.showToast("Success, you are a premium subscriber!", "success");
        this.setState({ isPremiumSubscriber: true, isPremium: true })
        this.logEvent(`buy_${isYearly ? 'yearly' : 'monthly'}_premium_subscription`);
        this.sendBuyMail(true, isYearly);
      }


    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription || error.message, "error")
      //this.showToast(error.localizedDescription, "error");
    }

  };
  sendBuyMail = async (isSub: boolean, isYearly?: boolean) => {
    return;
    try {
      await API.post('lyritunesrest', `/rest/send-email/`, {
        body: {
          emailFrom: 'no-reply@lyritunes.com',
          emailTo: 'cristian.anderes.radu@gmail.com',
          message: `Sucessfully bought premium`,
          subject: `LyriTunes Buy ${isSub ? (isYearly ? 'Yearly Sub' : 'Monthly Sub') : 'Premium'} - ${Platform.OS.toString()}!`
        }
      })
    } catch (e: any) {
      console.log("error", e)
    }
  }
  sendMail = async (emailTo: string, subject: string, message: string) => {
    return;
    try {
      await API.post('lyritunesrest', `/rest/send-email/`, {
        body: {
          emailFrom: 'no-reply@lyritunes.com',
          emailTo: emailTo,
          message: message,
          subject: subject
        }
      })
    } catch (e: any) {
      console.log("error", e)
    }
  }
  restorePurchase = async () => {
    console.log("restore")
    const prodSubId = Platform.OS === "android" ? "com.can.addixsub:addixsubplan" : "com.can.addixsub";

    try {
      const paywall = await navigationService.props.adapty.getPaywall('PlacementID');
      console.log("paywall", paywall)

      const products = await navigationService.props.adapty.getPaywallProducts(paywall);
      console.log("products", products)
      await navigationService.props.adapty.restorePurchases()
      const info = await navigationService.props.adapty.getProfile();
      console.log("info", JSON.stringify(info))
      if ((info?.subscriptions?.[prodSubId]?.isActive && !info?.subscriptions?.[prodSubId]?.isRefund)) {
        this.setState({ isPremium: true })
        this.showToast("Success, you are a premium subscriber!", "success")
        console.log("premium sub")
        return;

      }




    } catch (error: any) {
      console.log("error", error);
      this.showToast(error.localizedDescription, "error")
    }

  };
  showUpdateAlert = (appConfig: any) => {
    Alert.alert(
      'Update Needed',
      `Hey, there's a new version in town, please update for the best performance!`,
      [

        {
          text: 'Update',
          onPress: () => {
            Linking.openURL(Platform.OS === 'android' ? appConfig.androidApp : appConfig.iOSApp);
            setTimeout(() => {
              this.showUpdateAlert(appConfig);
            }, 1000);

          }
        },
      ],
      { cancelable: false })
  }
  setAppProp = (obj: any, callback?: any) => {
    let appConfig = { ...this.state };
    appConfig = { ...appConfig, ...obj };
    this.setState(appConfig, () => {
      if (callback) {
        callback();
      }
    });
  }

  setScreen = (screen: string, navigate?: boolean, params?: any, callback?: any) => {
    if (!this.firstScreen) {
      this.firstScreen = screen;
    } else {
      if (!navigationService.props.setHome) {
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
      }
    }
    this.setState({
      screen, routeParams: params
    }, () => {
      if (navigate) {
        navigationService.navigate(screen, params)
        this.logEvent(`navigate_${screen}`);
      }
      if (callback) {
        callback();
      }

    })
  };


  setURLOpener() {
    if (Platform.OS === 'web') {
      return;
    }
    const opener = (url: string) => {
      //Browser.open({ url })
      InAppBrowser.open(url)
    };
    const config: any = Auth.configure(null);
    config.oauth.urlOpener = opener;
    Auth.configure(config);
  }
  reportUserAccount = async (isBlocked?: boolean) => {

    const user = this.state.selectedUser;
    const userId = this.state.user.id;


    try {
      const reports = user.reports || [];
      if (!reports.includes(userId)) {
        reports.push(userId);
        const toSaveUser = {
          id: user.id,
          reports
        }
        const savedUser = (await API.graphql(graphqlOperation(updateUser, { input: toSaveUser })) as any).data.updateUser;
        user.reports = reports;
        //update the user in historyList
        if (isBlocked) {

          const appList = { ...this.state.appList }
          appList.items = appList.items.map((item: any) => {
            if (item.user && item.user?.id === user.id) {
              item.user = user;
            }
            return item;
          });
          appList.items = appList.items.filter((item: any) => {
            const postReports = item.reports?.length || 0;
            const userReports = item.user?.reports?.length || 0;
            const isBlockedByMe = item.user?.reports?.includes(this.state.user?.id);
            return postReports < 10 && userReports < 10 && !isBlockedByMe;
          })
          this.setState({ appList })
        }

        this.showToast(isBlocked ? "User successfully blocked" : "Reported user as inappropriate", "success")
      } else {
        //error({ message: "Already reported" });
        this.showToast(isBlocked ? "Already blocked" : "Already reported", "error")

      }
    } catch (e) {
      console.log("report user error", e)
    }


  }
  signOut = async () => {
    this.setUser(null);
    this.forceUpdate();
    this.setURLOpener();
    await Auth.signOut({ global: true });
    InAppBrowser.close();


    //this.setScreen('Home', true);

  }
  banUser = async () => {
    const user = this.state.reportUser;
    let ids = [];
    //generate 12 random ids with udid
    for (let i = 0; i < 12; i++) {
      ids.push(uuidv4());
    }
    const reports = ids;
    const toSaveUser = {
      id: user.id,
      reports
    }
    const savedUser = (await API.graphql(graphqlOperation(updateUser, { input: toSaveUser })) as any).data.updateUser;
    user.reports = reports;
    this.showToast("User banned", "success")

  }
  updateAccount = async (date: any) => {
    //this.registerEvent("update account");
    const account = { id: this.state.user.id, ...date };
    const acc = (await API.graphql(graphqlOperation(updateUser, { input: account })) as any).data.updateUser;
    this.setState({ user: acc })
  }

  logEvent = (eventName: string) => {

    const eve = `${Platform.OS.toString().toLowerCase()}_${eventName}`;
    try {
      if (Platform.OS === "web") {
        //ReactGA.event({ category: "", action: eve });
      } else {
        analytics().logEvent(eve, { platform: Platform.OS.toString().toLowerCase() });
      }
    } catch (e) {
      console.log("log error", JSON.stringify(e))
    }
  }
  setUser = async (user: any, callback?: any) => {
    if (this.isSettingUser) {
      return;
    }
    this.isSettingUser = true;
    if (this.state.screen === "Sign In") this.setScreen('Home', true)

    if (user) {


      let familyName, givenName: string;
      if (user.username.toLowerCase().indexOf('apple') !== -1) {
        familyName = user.attributes.given_name.split(" ")[1];
        givenName = user.attributes.given_name.split(" ")[0];
      } else {
        familyName = user.attributes.family_name;
        givenName = user.attributes.given_name;
      }

      let account: any = {
        id: user.attributes.sub,
        firstName: givenName,

        lastName: familyName,
        picture: user.username.toLowerCase().indexOf('facebook') !== -1 ? (user.attributes['picture'] ? JSON.parse(user.attributes['picture']).data.url : '') : user.attributes.picture,
        email: user.attributes.email
      };
      const token = Platform.OS !== "web" ? await messaging().getToken() : "";
      console.log("device token", token)
      if (token) {
        account.deviceToken = token;
      }

      let acc: any;
      config.aws_appsync_authenticationType = "AWS_IAM";
      Amplify.configure(config);
      let isAdmin = false;
      if (account.email?.toLowerCase() === 'yonodo10@gmail.com' || account.email?.toLowerCase() === 'bara.florentina@gmail.com') {
        isAdmin = true;
      }
      acc = (await API.graphql({ query: queries.getUser, variables: { id: account.id } }) as any).data.getUser;
      if (!acc) {
        acc = (await API.graphql(graphqlOperation(createUser, { input: account })) as any).data.createUser;

      } else {
        let updateAcc: any = {
          id: acc.id,

          picture: account.picture
        }
        if (token) {
          updateAcc.deviceToken = token;
        }
        acc = (await API.graphql(graphqlOperation(updateUser, {
          input: updateAcc
        })) as any).data.updateUser;
        if (Platform.OS !== 'web') {

        }
      }
      //console.log("acc", acc)
      this.setState({ user: acc, isAdmin }, () => {
        this.getUnseenNotifications();
        if (navigationService.props.initialNotification?.data) {
          this.gotoNotification(navigationService.props.initialNotification.data);
          navigationService.props.initialNotification = null;
        }
        if (navigationService.props.initialLinkToShare) {
          this.setScreen("Home", true);
          this.setAppProp({ initialLinkToShare: navigationService.props.initialLinkToShare })
          navigationService.props.initialLinkToShare = null;
        }
        //subscribe to graphql onCreateAddixNotification subscription for this user
        const subscription = API.graphql(graphqlOperation(onCreateAddixNotification, { filter: { userId: { eq: this.state.user?.id } } })).subscribe({
          next: (notificationData: any) => {
            //console.log("notificationData", notificationData)
            setTimeout(() => {
              this.getUnseenNotifications();
            }, 3000)
          }
        });
        if (Platform.OS !== 'web') {
          setTimeout(async () => {
            if ((this.state.isPremium && !acc.isPremium)) {
              let usInput: any = {
                id: acc.id,
                isPremium: this.state.isPremium
              }
              await API.graphql(graphqlOperation(updateUser, {
                input: usInput
              }))
            }

          }, 4000)
        }
      });

      try {
        InAppBrowser.close();
      } catch (e) {

      }



    } else {
      console.log("non on user")
      config.aws_appsync_authenticationType = "API_KEY";
      Amplify.configure(config);
      this.setState({
        user: null, isAdmin: false
      }, callback)


    }
    this.isSettingUser = false;

  }
  getUnseenNotifications = async () => {
    let notifications = (await API.graphql({
      query: queries.getNotificationsByTime,
      variables: { userId: this.state.user?.id, limit: 10, sortDirection: 'DESC', filter: { seen: { eq: false } } },
    }) as any).data.getNotificationsByTime
    notifications.items = notifications.items.filter((item: any) => item.notificationCreatedByUser);
    const hasUnseenNotifications = notifications.items.length !== 0;
    //console.log("hasUnseenNotifications", hasUnseenNotifications)
    //console.log("hasUnseenNotifications", notifications)
    this.setState({ hasUnseenNotifications });
  }
  appReview = async () => {
    if (InAppReview.isAvailable()) {
      InAppReview.RequestInAppReview()
        .then((hasFlowFinishedSuccessfully) => {
          // when return true in android it means user finished or close review flow
          console.log('InAppReview in android', hasFlowFinishedSuccessfully);

          // when return true in ios it means review flow lanuched to user.
          console.log(
            'InAppReview in ios has launched successfully',
            hasFlowFinishedSuccessfully,
          );

          // 3- another option:
          if (hasFlowFinishedSuccessfully) {
            // do something for ios
            // do something for android
          }

        })
        .catch((error) => {

          console.log("InAppReview error", error);
        });
    } else {
      console.log("InAppReview not available")
    }
  }
  error = (e: any) => {
    this.setState({ isBusy: false })
    this.showToast(e.message, 'error')
  }

  hideMenu = () => {
    const slideOutLeft = ({
      from: {
        transform: [{ translateX: 0 }], // Starts at its natural position
      },
      to: {
        transform: [{ translateX: -280 }], // Ends offscreen 100 px to the left
      },
    });
    //this.viewRef.fadeOut(1000).then(endState => setIsVisible(false));
    this.setState({ appMenuOpenedBack: false })
    navigationService.props.menuRef.animate(slideOutLeft, 200).then(() => {
      //make the navigationService.props.viewPostRef click trough
      this.setState({ appMenuOpened: false, appMenuOpenedBack: false })
    });

  }

  showToast = (message: string, type: 'error' | 'success' | 'info') => {
    let tp = type.toString();
    if (Platform.OS === 'web') {
      if (tp === 'error') {
        tp = 'danger';
      }
      const options: any = {
        placement: 'top',
        type: tp,
        duration: 3000,
        animationType: 'slide-in',
        animationDuration: 300,
        successColor: colors.primaryColor,
        offsetBottom: 70,
        offsetTop: 70,
        dangerColor: "#ad2b1a",
        warningColor: "orange",
        normalColor: "gray",
        textStyle: { fontSize: 15, color: "#ffffff" },
        swipeEnabled: true
      }
      toast.show(message, options);
    } else {
      this.props.Toast.show({
        type: tp,
        position: 'top',
        topOffset: 70,
        visibilityTime: 3000,
        text1: message,

      });
    }
    //toast.show("Show custom toast", { data: { title: 'Toast title' } })
  }

  render() {
    return (
      <AppContext.Provider value={
        {
          state: this.state,
          showToast: this.showToast,
          sendMail: this.sendMail,
          playRewardedAndGo: this.playRewardedAndGo,
          postText: this.postText,
          deleteThread: this.deleteThread,
          clearChat: this.clearChat,
          getChatMessage: this.getChatMessage,
          waitSomeSeconds: this.waitSomeSeconds,
          postAIChat: this.postAIChat,
          postLink: this.postLink,
          reportPost: this.reportPost,
          postCampaign: this.postCampaign,
          likePost: this.likePost,
          getTextWithFirstLetterCapital: this.getTextWithFirstLetterCapital,
          postPhoto: this.postPhoto,
          deletePost: this.deletePost,
          getUserLevel: this.getUserLevel,
          gotoNotification: this.gotoNotification,
          reportUserAccount: this.reportUserAccount,
          sendPushNotification: this.sendPushNotification,
          banUser: this.banUser,
          postComment: this.postComment,
          reportComment: this.reportComment,
          startQuittingCampaign: this.startQuittingCampaign,
          showRewarded: this.showRewarded,
          playRewardedAdAndGenerate: this.playRewardedAdAndGenerate,
          hideMenu: this.hideMenu,
          getUnseenNotifications: this.getUnseenNotifications,
          shareMe: this.shareMe,
          gotoStore: this.gotoStore,
          getListItems: this.getListItems,
          appReview: this.appReview,
          buyPremiumSubscription: this.buyPremiumSubscription,
          restorePurchase: this.restorePurchase,
          logEvent: this.logEvent,
          updateAccount: this.updateAccount,
          setUser: this.setUser,
          signOut: this.signOut,
          setScreen: this.setScreen,
          setAppProp: this.setAppProp,
          gotoChatHistory: this.gotoChatHistory,
          showDeleteThread: this.showDeleteThread,

        }}>
        {this.props.children}
      </AppContext.Provider>)
  }
}
