import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, TextStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { Feather } from '@expo/vector-icons';
interface State {
    initial?: number
}
interface Props {
    itemWidth?: number,
    isRow?: boolean,
    activeColor?: string,
    textColor?: string,
    initial?: number,
    value?: string,
    textStyle?: TextStyle,
    data?: { label: string, type: string }[],
    onSelect?: any,
    style?: ViewStyle
}
class RadioGroupRow extends React.Component<Props, State>  {
    static contextType = AppContext;
    declare context: IContext


    constructor(props: any) {
        super(props);
        this.state = { initial: 0 };


    }

    componentDidMount() {
        let initial = this.props.initial;
        if (this.props.value) {
            initial = this.props.data?.findIndex((item) => item.type == this.props.value)
        }

        this.setState({ initial: initial })

    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.initial !== this.props.initial) {
            this.setState({ initial: this.props.initial })
        }
        if (prevProps.value !== this.props.value) {
            this.setState({ initial: this.props.data?.findIndex((item) => item.type == this.props.value) })
        }
    }


    render() {

        return (
            <View style={[style.row, style.hcenter, this.props.style]}>
                {this.props.data?.map((item: any, index: number) => {
                    return (
                        <TouchableOpacity key={index} onPress={() => { this.props.onSelect(item); this.setState({ initial: index }) }} >
                            <View style={[style.row, { alignItems: 'center', justifyContent: 'center', width: this.props.itemWidth || undefined, margin: 10, marginRight: 5, marginLeft: 5, borderRadius: 25, padding: 5, borderColor: colors.bluePrimary, borderWidth: 2, backgroundColor: this.state.initial == index ? colors.bluePrimary : '#ffffff' }]}>
                                <Text style={[this.props.textStyle, { color: this.state.initial == index ? '#ffffff' : colors.bluePrimary, fontWeight: 'bold' }]}>{item.label}</Text>
                            </View>


                        </TouchableOpacity>
                    )

                })
                }
            </View>
        )
    };

}


const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});



export default RadioGroupRow;
