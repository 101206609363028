import * as React from 'react';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle
} from 'react-native';
import publicIP from 'react-native-public-ip';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import PrimaryButton from "../components/primary-button";
import { AntDesign, FontAwesome, FontAwesome5, Foundation, SimpleLineIcons } from "@expo/vector-icons";
import Sound from "react-native-sound";
import ButtonBar from "../components/button-bar";
import navigationService from "../services/navigation-service";
import ViewShot, { captureRef } from "react-native-view-shot";
import { API, graphqlOperation, Storage } from "aws-amplify";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
//import { InAppBrowser } from 'react-native-inappbrowser-reborn'

///@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import * as queries from "../graphql/queries";

import InAppBrowser from 'react-native-inappbrowser-reborn';

interface Props {
  style?: ViewStyle
}

interface State {

}

class ViewPostScreen extends React.Component<Props, State> {

  static contextType = AppContext;
  declare context: IContext


  constructor(props: any) {
    super(props);
    this.state = {

    }
  }

  componentWillUnmount() {

  }

  async componentDidMount() {

  }

  render() {


    return (
      <View style={[style.page, this.props.style, { backgroundColor: '#000000' }]}>
        <View style={[style.container, {
          maxWidth: undefined,
          padding: 0, backgroundColor: '#000000'
        }]}>
          <ImageBackground source={{ uri: this.context.state.imagePreview }} imageStyle={{ borderRadius: 0, resizeMode: 'contain', width: '100%', height: '100%' }} style={{ width: '100%', height: '100%', marginBottom: 10 }} >
          </ImageBackground>
          <TouchableOpacity style={[style.row, style.fullWidth, style.hcenter, { position: 'absolute', bottom: 20 }]} onPress={() => {
            this.context.setAppProp({ 'imagePreview': null });
          }}><View style={[style.row, style.vcenter, style.hcenter, { width: 40, height: 40, borderRadius: 20, marginTop: 10, backgroundColor: colors.pinkish }]}>
              <AntDesign name="close" size={18} color={colors.greaysh} />
            </View></TouchableOpacity>
        </View>

      </View>

    )
  };

}

export default ViewPostScreen

const styles = StyleSheet.create({
  input: {
    padding: 5,
    height: 35,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: colors.textColor,
    color: colors.textColor
  },
  screenItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    backgroundColor: colors.textColor
  }
});
