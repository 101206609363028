import { __extends } from "tslib";
import { ServiceException as __ServiceException, } from "@aws-sdk/smithy-client";
var LexRuntimeServiceServiceException = (function (_super) {
    __extends(LexRuntimeServiceServiceException, _super);
    function LexRuntimeServiceServiceException(options) {
        var _this = _super.call(this, options) || this;
        Object.setPrototypeOf(_this, LexRuntimeServiceServiceException.prototype);
        return _this;
    }
    return LexRuntimeServiceServiceException;
}(__ServiceException));
export { LexRuntimeServiceServiceException };
