import { StyleSheet } from 'react-native';
import colors from './colors';

export default StyleSheet.create({
  busyIndicator: {
    position: 'absolute',
    marginLeft: -16,
    marginTop: -16,
    top: '50%',
    left: '50%'
  },
  textInput: {
    backgroundColor: colors.grey,
    padding: 11,
    fontSize: 18,
    fontFamily: 'Jost',
    borderRadius: colors.borderRadius,
    color: colors.textColor,
    borderWidth: 0,
  },
  primaryButtonBusyIndicator: {
    position: 'absolute',
    marginTop: -12,
    top: '50%',
    right: 10
  },
  button: {
    borderRadius: colors.borderRadius,
    backgroundColor: colors.primaryColor,
    padding: 10,
  },
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  container: {
    flex: 1,
    padding: 5,
    width: '100%',
    maxWidth: colors.maxWidth,
    backgroundColor: colors.backgroundColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  page: {
    flex: 1,
    padding: 0,
    backgroundColor: colors.backgroundColor,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadow: {
    shadowColor: "#000000",
    shadowOffset: { width: 5, height: 5 },
    shadowRadius: 15,
    elevation: 2,
    shadowOpacity: 0.3
  },
  premiumShadow: {
    shadowColor: colors.primaryColor,
    shadowOffset: { width: 3, height: 3 },
    shadowRadius: 5,
    elevation: 3,
    shadowOpacity: 0.3
  },
  shadowPrimary: {
    shadowColor: '#000000',
    shadowOffset: { width: 3, height: 3 },
    shadowRadius: 15,
    elevation: 2,
    shadowOpacity: 0.2
  },
  shadowButton: {
    shadowColor: colors.primaryColor,
    shadowOffset: { width: 3, height: 9 },
    shadowRadius: 11,
    elevation: 11,
    shadowOpacity: 0.4
  },
  shadowMeme: {
    shadowColor: '#000000',
    shadowOffset: { width: 3, height: 3 },
    shadowRadius: 4,
    elevation: 4,
    shadowOpacity: 0.2
  },
  shadowAddButton: {
    shadowColor: "#000000",
    shadowOffset: { width: 4, height: 4 },
    shadowRadius: 5,
    elevation: 5,
    shadowOpacity: 0.3
  },
  shadowBuy: {
    shadowColor: "#000000",
    shadowOffset: { width: 4, height: 2 },
    shadowRadius: 6,
    elevation: 6,
    shadowOpacity: 0.15
  },
  shadowModal: {
    shadowColor: "#000000",
    shadowOffset: { width: 5, height: 5 },
    shadowRadius: 3,
    elevation: 20,
    shadowOpacity: 1
  },
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  fullSize: {
    width: '100%',
    height: '100%'
  },
  shareButton: {
    width: 50,
    marginRight: 12,
    height: 50
  },
  shareButtonIcon: {
    width: 50,
    height: 50
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  text: {
    fontSize: 16,
    color: colors.textColor,
    fontFamily: 'Jost'
  },
  textShadow: {
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 3,
    fontSize: 16,
    textShadowColor: '#000',
  },
  textShadowWhite: {
    color: '#fff',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    fontSize: 16,
    textShadowColor: '#000',
  },

  vcenter: {
    alignItems: 'center'
  },
  vcenterself: {
    alignSelf: 'center'
  },
  hcenter: {
    justifyContent: 'center'
  },
  hright: {
    justifyContent: 'flex-end'
  },
  hleft: {
    justifyContent: 'flex-start'
  },
  userButton: {
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.secondaryColor,
    borderWidth: 1,
    backgroundColor: '#fff',
  },
  userButtonPost: {
    overflow: 'hidden',
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    borderColor: colors.primaryColor,
    borderWidth: 0,
    backgroundColor: '#fff',
  }
});
