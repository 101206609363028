// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
export var SEND_UPLOAD_PROGRESS_EVENT = 'sendUploadProgress';
export var SEND_DOWNLOAD_PROGRESS_EVENT = 'sendDownloadProgress';
export var NETWORK_ERROR_MESSAGE = 'Network Error';
export var NETWORK_ERROR_CODE = 'ECONNABORTED';
export var ABORT_ERROR_MESSAGE = 'Request aborted';
export var ABORT_ERROR_CODE = 'ERR_ABORTED';
export var CANCELED_ERROR_MESSAGE = 'canceled';
export var CANCELED_ERROR_CODE = 'ERR_CANCELED';
export var CONTENT_SHA256_HEADER = 'x-amz-content-sha256';
