import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  TextInput,
  ScrollView,
  Platform, ImageBackground, Linking
} from 'react-native';
import colors from '../constants/colors';
import * as Animatable from 'react-native-animatable';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { API, graphqlOperation, Storage } from "aws-amplify";
import navigationService from "../services/navigation-service";
//@ts-ignore
import UserButton from "./user-button";
import PrimaryButton from "./primary-button";
import { AddixPost } from "../models";
import LyriItemBar from "./iitem-bar";
import { Feather, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { InAppBrowser } from 'react-native-inappbrowser-reborn'
import {
  updateAddixPost
} from "../graphql/mutations";
import ActionSheet, { SheetManager } from 'react-native-actions-sheet';
import Alert from './alert';
import ItemBar from './iitem-bar';
import LinkPreview from './link-preview';
import UserButtonLong from './user-button-long';
import DaysPassed from './days-passed';
import CampaignScreen from '../screens/campaign';

interface State {
  isBusyLink?: boolean
  isExpanded?: boolean
}

interface Props {
  item: AddixPost,
  index?: number
  padding?: number
  imageSize?: number
  hasRewarded?: boolean
  isMyPost?: boolean
  backgroundColor?: string
  hideBar?: boolean
  onPress: any
  style?: ViewStyle
}
const fadeInOut = {
  0: {
    opacity: 0.5
  },
  0.5: {
    opacity: 1
  },
  1: {
    opacity: 0.5
  }
};
class PostItem extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext



  constructor(props: any) {
    super(props);
    this.state = { isBusyLink: false }
    this.getImageUrl();
  }
  async getImageUrl() {
    if (this.props.item.imageKey) {
      this.props.item.imageUrl = await Storage.get(this.props.item.imageKey, { level: 'public' });
    }
  }

  openUserMenu(user: any) {
    //this.context.openReportUser(user);
  }

  async componentDidMount() {

  }
  playRewardedAdAndView = () => {
    this.context.playRewardedAdAndGenerate(this.itemPress.bind(this, true));
  }
  itemPress = async (force?: boolean) => {
    console.log("itemPress", this.props.item, force)
    if (!this.props.isMyPost) {
      if (this.props.hasRewarded && !this.context.state.isPremium && !force) {
        this.context.showRewarded(this.itemPress.bind(this, true))
        return;
      }
      this.context.setAppProp({ viewPost: this.props.item });
      if (this.props.onPress) this.props.onPress();
    } else {
      if (this.props.onPress) this.props.onPress();
    }
  }
  openLinkInBrowser() {

  }

  reportUser() {

  }

  render() {
    const busyIndicator = () => {
      if (this.state.isBusyLink) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      }
    }
    const postRenderer = () => {
      switch (this.props.item?.postItemType) {
        case "text":
          return <View style={[style.column, style.hcenter, style.fullWidth, { marginTop: 5, marginBottom: 10 }]}>
            <Text style={{
              marginLeft: 5,
              color: colors.textColor,
              fontFamily: 'Jost',
              fontSize: 18,
              width: '100%',
              marginTop: 0,
              marginBottom: 0
            }}>{this.props.item.text}</Text>
          </View>
          break;
        case "campaign":
          //console.log("campaign", this.props.item)

          return <View style={[style.column, style.hcenter, style.fullWidth, { marginTop: 5, marginBottom: 10 }]}>
            {this.props.item.text ? <Text style={{
              marginLeft: 5,
              color: colors.textColor,
              fontFamily: 'Jost',
              fontSize: 18,
              width: '100%',
              marginTop: 0,
              marginBottom: 10
            }}>{this.props.item.text}</Text> : null}
            <CampaignScreen user={this.props.item.user} style={{ width: '100%' }} endDate={this.props.item.savedAt} campaignStart={this.props.item.user.campaignStart} campaignType={this.props.item.user.campaignType}></CampaignScreen>
          </View>
          break;
        case "link":
          return <View style={[style.column, style.hcenter, style.fullWidth, { marginTop: 5, marginBottom: 10 }]}>
            {this.props.item.text ? <Text style={{
              marginLeft: 5,
              color: colors.textColor,
              fontFamily: 'Jost',
              fontSize: 18,
              width: '100%',
              marginTop: 0,
              marginBottom: 10
            }}>{this.props.item.text}</Text> : null}
            <LinkPreview link={this.props.item.postLink} style={{ width: '100%' }} imageSize={this.props.imageSize} imagePreview={this.props.item.imagePreview} description={this.props.item.description}></LinkPreview>
          </View>
          break;
        case "image":
          return <View style={[style.column, style.hcenter, style.fullWidth, { marginTop: 5, marginBottom: 10 }]}>
            {this.props.item.text ? <Text style={{
              marginLeft: 5,
              color: colors.textColor,
              fontFamily: 'Jost',
              fontSize: 18,
              width: '100%',
              marginTop: 0,
              marginBottom: 10
            }}>{this.props.item.text}</Text> : null}
            <TouchableOpacity onPress={() => {
              this.context.setAppProp({ 'imagePreview': this.props.item.imageUrl });
            }}>
              <ImageBackground source={{ uri: this.props.item.imageUrl }} imageStyle={{ borderRadius: colors.borderRadius, resizeMode: 'cover', width: '100%', height: 300 }} style={{ width: '100%', height: 300, marginBottom: 10 }} >

              </ImageBackground>
            </TouchableOpacity>
          </View>
          break;
        case "ai-message":
          return <View style={[style.column, style.fullWidth, { padding: 20, overflow: 'hidden', maxHeight: this.state.isExpanded ? undefined : 300, marginTop: 10, marginBottom: 10, borderRadius: colors.borderRadius, backgroundColor: colors.grey }]}>
            <View style={[style.row, style.fullWidth, { paddingRight: 10 }]}>
              <UserButton user={this.props.item.aiChat.user} style={{ marginRight: 10 }} size={35} ></UserButton>
              <Text style={{
                fontFamily: 'Jost',
                marginTop: 5,
                fontSize: 18,
                flexGrow: 1,
                paddingRight: 20,
                color: colors.neutralBlue
              }}>{this.props.item.aiChat.userMessage}</Text>
            </View>
            <View style={[style.row, style.fullWidth, { marginTop: 20 }]}>
              <UserButton user={{ picture: 'https://raw.githubusercontent.com/crisand/addix/main/maya.png' }} style={{ marginRight: 10 }} size={35} ></UserButton>
              <Text style={{
                fontFamily: 'Jost',
                flexGrow: 1,
                marginTop: 5,
                paddingRight: 20,
                fontSize: 18,
                color: colors.neutralBlue
              }}>{this.props.item.aiChat.assistantMessage}</Text>
            </View>
          </View>
          break;
      }
    }
    return (
      this.props.item ?
        <View style={[this.props.style, { width: '100%', paddingTop: 5 }]}>

          {!this.context.state.isPremium && (this.props.index || 0) !== 0 && ((this.props.index || 0)) % this.context.state.adData?.banSkip === 0 && this.context.state.adData?.enabled && this.context.state.checkedStore && Platform.OS !== 'web' && this.context.state.appConfig ?

            <View style={[{
              marginTop: 0,
              backgroundColor: this.props.backgroundColor || colors.pinkish,
              padding: this.props.padding || 10,
              borderRadius: colors.borderRadius,
              height: 320,
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 5,
              width: '100%',
            }]}>

              <View style={{ marginBottom: 10, width: '100%', alignItems: 'flex-start' }}>
                <UserButton size={35} user={{ firstName: "A", lastName: "D" }}></UserButton>
              </View>
              <navigationService.props.BannerAd onAdFailedToLoad={() => {

              }}
                unitId={this.context.state.adData?.ban}
                size={navigationService.props.BannerAdSize.MEDIUM_RECTANGLE}
                requestOptions={{
                  requestNonPersonalizedAdsOnly: this.context.state.requestNonPersonalizedAdsOnly
                }}
              />
            </View> : null}


          <View style={[{
            marginTop: 5,
            marginBottom: 5,
            backgroundColor: this.props.backgroundColor || colors.pinkish,
            padding: this.props.padding || 10,
            borderRadius: colors.borderRadius,
            width: '100%',
          }]}>

            <View style={[style.fullWidth, style.column, {
              alignItems: 'flex-start'
            }]}>
              {/* <UserButton style={{ position: 'absolute', right: 0, bottom: 0 }} isPremium={this.props.item?.user?.isPremium} hideBorder={true} onPress={this.openUserMenu.bind(this, this.props.item?.user)} user={this.props.item?.user} size={35}></UserButton> */}
              <UserButtonLong style={{}} hideDetails={true} user={this.props.item?.user} ></UserButtonLong>
              <DaysPassed style={{ position: 'absolute', right: 5, bottom: 5 }} date={this.props.item?.savedAt}></DaysPassed>
              <TouchableOpacity style={[style.fullWidth]} onPress={() => {
                this.setState({ isExpanded: !this.state.isExpanded })
              }}>
                {postRenderer()}
              </TouchableOpacity>
              {!this.props.hideBar ? <ItemBar notPad={true} style={{
                marginTop: 10

              }} post={this.props.item} /> : <View style={{ height: 30 }}></View>}
            </View>



          </View>

        </View> : <View></View>

    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default PostItem;
