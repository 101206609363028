import { FontAwesome, FontAwesome5, Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import {
    StyleSheet,
    TextInput,
    Text,
    View,
    TouchableOpacity,
    ActivityIndicator,
    ScrollView
} from 'react-native';
import {
    createUserMessageThreads,
    createUserMessage
} from "./../graphql/mutations";
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from "../store/app-provider";
import * as queries from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { UserMessageThreads } from '../models';
import ItemsList from '../components/items-list';
import UserButton from '../components/user-button';

interface Props {

}

interface State {
    isBusy?: boolean,
    message?: string
    thread?: UserMessageThreads
}

class MessagesScreen extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    itemList: any
    constructor(props: any) {
        super(props);
        this.state = {
            isBusy: false,
            message: ""
        }
    }

    async componentDidMount() {

    }

    async getMessagesThreads(token?: string) {
        let messagesBySender = (await API.graphql({
            query: queries.getThreadsBySender,
            variables: { senderId: this.context.state.user?.id, limit: 200, sortDirection: 'ASC', nextToken: token },
        }) as any).data.getThreadsBySender
        messagesBySender.items = messagesBySender.items.filter((item: any) => item.sender);
        let messagesByRecipient = (await API.graphql({
            query: queries.getThreadsByRecipient,
            variables: { recipientId: this.context.state.user?.id, limit: 200, sortDirection: 'ASC', nextToken: token },
        }) as any).data.getThreadsByRecipient
        messagesByRecipient.items = messagesByRecipient.items.filter((item: any) => item.recipient);
        return { items: messagesBySender.items.concat(messagesByRecipient.items), nextToken: messagesBySender.nextToken };
    }


    render() {
        const renderItem = ({ item, index }: any) => {
            return <TouchableOpacity key={index} onPress={() => {
                this.context.setAppProp({ selectedUser: item.senderId === this.context.state.user?.id ? item.recipient : item.sender });
                this.context.setScreen('Message', true);
            }}>

                <View style={[style.row, style.vcenter, {
                    marginTop: 15, marginBottom: 10, padding: 20, backgroundColor: colors.pinkish,
                    borderRadius: colors.borderRadius
                }]}>
                    <UserButton hideBorder={false} user={item.senderId === this.context.state.user?.id ? item.recipient : item.sender} size={40}></UserButton>
                    <Text style={[style.text, { fontSize: 16, marginLeft: 10, flex: 1, marginRight: 10 }]}>{item.lastMessage}</Text>
                    <FontAwesome5 name="arrow-alt-circle-right" size={24} color={colors.bluePrimary} />

                </View>
            </TouchableOpacity>
        }
        return (

            <View style={style.page}>
                <View style={[style.container, { padding: 20 }]}>

                    <ItemsList style={{ marginBottom: 10 }} ref={ref => {
                        this.itemList = ref
                    }} cache={false} disableBottomScroll={true} renderItem={renderItem} disableRefresh={false} stateKey={'messageThreads'} fn={this.getMessagesThreads.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.messageThreads}></ItemsList>
                </View>
            </View>

        )
    };

}

export default MessagesScreen;

const styles = StyleSheet.create({});
