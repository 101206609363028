import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Platform, AsyncStorage } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { AntDesign, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { SheetManager } from 'react-native-actions-sheet';
import PrimaryButton from './primary-button';
import * as Animatable from 'react-native-animatable';
import IconMenu from './icon-menu';
interface State {
    appMenu?: any

}
interface Props {
    style?: ViewStyle
}
const fadeInOut = {
    0: {
        opacity: 0.5
    },
    0.5: {
        opacity: 1
    },
    1: {
        opacity: 0.5
    }
};

class AppMenu extends React.Component<Props, State>  {
    static contextType = AppContext;
    declare context: IContext
    noClicks: number = 0;
    constructor(props: any) {
        super(props);

        this.state = {

            appMenu: [
                {
                    label: "Premium",
                    icon: <FontAwesome5 name={"shopping-cart"} size={24} color="black" />
                },
                {
                    label: "Terms and Conditions",
                    icon: <FontAwesome5 name={"file-alt"} size={24} color="black" />

                }, {
                    label: "Privacy Policy",
                    icon: <FontAwesome5 name={"file-alt"} size={24} color="black" />
                }]
        }

    }

    itemPress = async (item: any) => {
        this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false })
        SheetManager.hide("menu_sheet");
        switch (item.label) {
            case "My Campaign":
                this.context.setScreen('Campaign', true);
                break
            case "Messages":
                this.context.setScreen('Messages', true);
                break
            case "Terms and Conditions":
                this.context.setScreen('Terms', true);
                break;
            case "Privacy Policy":
                this.context.setScreen('Privacy', true);
                break;
            case "Admin":
                this.context.setScreen('AIStats', true);
                break;

            case 'Premium':
                setTimeout(() => {
                    SheetManager.show('buy_premium');
                }, 500)

                break
            case 'Feedback':
                setTimeout(() => {
                    SheetManager.show('feedback_sheet');
                }, 500)
                break
        }
    }

    async componentDidMount() {
        //if (Platform.OS !== "web" && !this.context.state.isPremium && !this.context.state.user?.isOnCampaign) {
        if (this.context.state.user) {
            let userMenu = [
                {
                    label: "Feedback",
                    icon: <FontAwesome5 name={"inbox"} size={24} color="black" />
                }]
            if (this.context.state.isAdmin) {
                userMenu.push({
                    label: "Admin",
                    icon: <FontAwesome5 name={"cog"} size={24} color="black" />
                })
            }
            //add this menu at the statrt of the array
            this.setState({ appMenu: [...userMenu, ...this.state.appMenu] })
        } else {
            this.setState({ appMenu: [...this.state.appMenu] })
        }





    }


    render() {

        return (
            <View style={[this.props.style, style.column, { backgroundColor: "#ffffff", paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }]}>
                <IconMenu data={this.state.appMenu} style={{ width: '100%' }} onPress={(item: any) => {
                    this.itemPress(item)
                }
                }></IconMenu>
                <View style={{ flex: 1 }}></View>
                {this.context.state.user ? <PrimaryButton align={'right'} onPress={() => {

                    this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false }, () => {
                        this.context.signOut();
                    })
                }} icon={
                    <FontAwesome style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='sign-out'></FontAwesome>} label={'Sign Out'} style={{
                        width: '100%'
                    }} /> : <PrimaryButton align={'left'} onPress={() => {
                        this.context.setScreen("Sign In", true);
                        this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false })
                    }} icon={
                        <FontAwesome size={20} color={colors.iconColorWhite} name='user'></FontAwesome>} label={'Sign In'} style={{
                            width: '100%'
                        }} />}
            </View>
        )
    };

}


const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});



export default AppMenu;
