import { AntDesign, Entypo, Feather, FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    Text,
    View,
    FlatList,
    Platform,
    Clipboard,
    RefreshControl,
    AsyncStorage, Image, Modal, BackHandler, ScrollView, TextInput, ActivityIndicator, Keyboard
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from './../services/navigation-service';
import { AppContext, IContext } from '../store/app-provider';
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";
import UserButton from '../components/user-button';
import RadioGroup from '../components/radio-group';
import PrimaryButton from '../components/primary-button';
import RadioGroupRow from '../components/radio-group-row';


interface Props {

}

interface State {
    isBusy?: boolean
    type: string
    shareType?: string
}

class AIHomeScreen extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    itemList: any
    shareMessage: any
    quitType: any = { label: 'Nicotine', type: 'nicotine' }

    constructor(props: any) {
        super(props);
        this.state = { type: 'public', shareType: 'app' }
    }

    createPost() {
        if (Platform.OS === "web") {
            SheetManager.show('download_sheet_home');
            return;
        }


    }
    copyPaste = async (text: string) => {
        Clipboard.setString(text)
        this.context.showToast('Copied!', 'success');
        this.context.logEvent('copy_message');
    }
    async componentDidMount() {
        navigationService.props.setHome = true;
    }

    componentDidUpdate(prevPops: any) {

    }

    async getHistory(token?: string) {
        const history = { items: [], nextToken: '' }
        return { items: history.items, nextToken: history.nextToken };
    }
    clearChat() {
        this.context.clearChat();
    };
    reportUser() {
    }

    setPrivate(e: any) {
        if (e.type !== this.state.type) {
            this.setState({ type: e.type }, () => {
                this.itemList.refresh();
            })
        }

    }
    async shareMe() {
        const url = `https://addix.mobi/aimessage?id=${this.shareMessage.id}`;
        //const url = `http://localhost:19006/aimessage?id=${this.shareMessage.id}`;
        const shareObj = { url, title: this.shareMessage.ai }
        await SheetManager.hide(`show_share_options`);
        setTimeout(async () => {
            switch (this.state.shareType) {
                case 'app':
                    if (!this.context.state.user) {
                        this.context.showToast("Please login to share on the app's public timeline", 'error');
                        return;
                    }
                    this.makePublic();
                    break;
                case 'social':
                    this.context.shareMe(shareObj);
                    break;
                case 'both':
                    if (this.context.state.user) {
                        this.makePublic();
                    }
                    this.context.shareMe(shareObj);
                    break;
            }
        }, 500);

    }
    async makePublic() {
        await this.context.postAIChat(this.shareMessage, this.quitType);
        this.context.showToast('Messaged posted!', 'success');
    }
    async submitText() {

        if (Platform.OS === "web" && (window as any)?.document.location.href?.indexOf('localhost') === -1) {
            SheetManager.show('download_sheet_home');
            return;
        }
        if (!this.context.state.userPrompt || this.context.state.isBusy) return;
        try {
            await this.context.getChatMessage();

        } catch (e) {
        }
    }

    render() {
        const busyIndicator = () => {
            if (this.context.state.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }
        const renderItem = ({ item, index }: any) => {
            return <View style={[style.column, style.fullWidth, { marginTop: 10, marginBottom: 10, borderRadius: colors.borderRadius, backgroundColor: colors.pinkish, padding: 20 }]} key={item.id}>
                <View style={[style.row, style.fullWidth, style.vcenter, { paddingRight: 10 }]}>
                    <UserButton user={this.context.state.user} style={{ marginRight: 10 }} size={40} ></UserButton>
                    <Text style={{
                        fontFamily: 'Jost',
                        fontSize: 18,
                        flexGrow: 1,
                        paddingRight: 20,
                        color: colors.neutralBlue
                    }}>{item.user}</Text>
                </View>
                <View style={[style.row, style.fullWidth, style.vcenter, { marginTop: 20 }]}>

                    <UserButton user={{ picture: 'https://raw.githubusercontent.com/crisand/addix/main/maya.png' }} style={{ marginRight: 10 }} size={40} ></UserButton>
                    {item.ai ? <Text style={{
                        fontFamily: 'Jost',
                        flexGrow: 1,
                        paddingRight: 20,
                        fontSize: 18,
                        color: colors.neutralBlue
                    }}>{item.ai}</Text> : <ActivityIndicator size="small" color={colors.busyIndicator} />}
                </View>
                {item.ai ? <View style={[style.row, style.fullWidth, { marginTop: 10, justifyContent: 'flex-end' }]}>
                    <TouchableOpacity style={{ marginRight: 10 }} onPress={() => {
                        this.copyPaste(item.ai);
                    }}>
                        <Feather name="clipboard" size={25} color={colors.bluePrimary} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        this.shareMessage = item;
                        SheetManager.show(`show_share_options`);
                    }}>
                        <AntDesign name="sharealt" size={25} color={colors.bluePrimary} />
                    </TouchableOpacity>
                </View> : null}
                {!this.context.state.isPremium && item.ai && (index || 0) !== 0 && index % this.context.state.adData?.banSkipBig !== 0 && ((index || 0)) % this.context.state.adData?.banSkip === 0 && this.context.state.adData?.enabled && this.context.state.checkedStore && Platform.OS !== 'web' && this.context.state.appConfig ?
                    <View style={[{
                        marginTop: 10,
                        backgroundColor: 'transparent',
                        height: 60,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 0,
                        width: '100%'
                    }]}>
                        <navigationService.props.BannerAd onAdFailedToLoad={() => {
                        }}
                            unitId={this.context.state.adData?.ban}
                            size={navigationService.props.BannerAdSize.BANNER}
                            requestOptions={{
                                requestNonPersonalizedAdsOnly: this.context.state.requestNonPersonalizedAdsOnly
                            }}
                        />
                    </View> : null}
                {!this.context.state.isPremium && item.ai && (index || 0) !== 0 && index % this.context.state.adData?.banSkipBig === 0 && this.context.state.adData?.enabled && this.context.state.checkedStore && Platform.OS !== 'web' && this.context.state.appConfig ?
                    <View style={[{
                        marginTop: 10,
                        backgroundColor: '#fff',
                        height: 260,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 0,
                        width: '100%',
                    }]}>

                        <navigationService.props.BannerAd onAdFailedToLoad={() => {

                        }}
                            unitId={this.context.state.adData?.ban}
                            size={navigationService.props.BannerAdSize.MEDIUM_RECTANGLE}
                            requestOptions={{
                                requestNonPersonalizedAdsOnly: this.context.state.requestNonPersonalizedAdsOnly
                            }}
                        />
                    </View> : null}
            </View>

        }
        return (
            <View style={style.page}>
                <View style={[style.container, { padding: 10 }]}>
                    <ItemsList ref={ref => {
                        this.itemList = ref
                    }} cache={false} renderItem={renderItem} disableRefresh={true} stateKey={'chatsList'} fn={this.getHistory.bind(this)} nextTokenName={'nextToken'} hideNone={true} stateItem={this.context.state.chatsList}></ItemsList>
                    <View style={[style.row, style.fullWidth, style.vcenter, { opacity: this.context.state.isBusy ? 0.5 : 1, maxWidth: '100%' }]}>

                        <TouchableOpacity style={{ marginRight: 10 }} onPress={() => {
                            this.clearChat();
                        }}>
                            <MaterialCommunityIcons name="chat-plus-outline" size={30} color={colors.bluePrimary} />
                        </TouchableOpacity>
                        <View style={{ height: '100%', flex: 1, marginRight: 10 }}>
                            <TextInput
                                autoCapitalize={'sentences'}
                                value={this.context.state.userPrompt || ''}
                                autoCorrect={false}
                                blurOnSubmit={true}
                                maxLength={250}
                                enablesReturnKeyAutomatically={true}
                                onSubmitEditing={() => {
                                    this.submitText();
                                }}
                                placeholder="What seems to be the problem? Let's talk!"
                                multiline={true}
                                numberOfLines={3}
                                onChangeText={(text: string) => {
                                    if (this.context.state.isBusy) return;
                                    this.context.setAppProp({ userPrompt: text })
                                }}
                                style={[style.textInput, {
                                    backgroundColor: colors.pinkish,
                                    textAlignVertical: 'center',
                                    height: 80,
                                    flexGrow: 1,
                                    fontFamily: 'Jost',
                                    color: colors.neutralBlue,
                                    width: '100%'
                                }]}
                            ></TextInput>
                        </View>
                        <TouchableOpacity onPress={() => {
                            this.submitText();
                            //hide keyboard
                            Keyboard.dismiss();

                        }}>
                            <MaterialCommunityIcons name="send" size={30} color={colors.bluePrimary} />
                        </TouchableOpacity>
                    </View>

                </View>
                <ActionSheet closeOnPressBack={true} onClose={() => {
                }} keyboardShouldPersistTaps={'handled'} containerStyle={{
                    maxWidth: 400, borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRadius: colors.borderRadius
                }} defaultOverlayOpacity={0.5} id={`show_share_options`}>
                    <View style={[style.fullWidth, style.hcenter, { padding: 20 }]}>

                        <RadioGroup activeColor={colors.pinkish} style={{
                            width: '100%',
                            marginBottom: 10
                        }} textColor={colors.textColor} initial={0} textStyle={{
                            color: colors.textColor,
                            fontSize: 18
                        }}
                            data={[{
                                label: "Share on app's timeline",
                                type: 'app'
                            }, {
                                label: "Share on Social Media",
                                type: 'social'
                            }, {
                                label: "Share on both",
                                type: 'both'
                            }]}
                            selectedBtn={(e: any) => {
                                console.log("e", e)
                                this.setState({ shareType: e.type })
                            }}
                        />
                        <RadioGroupRow style={{ marginBottom: 10 }} itemWidth={75} onSelect={(data: any) => {
                            this.quitType = data;
                        }} value={this.context.state.user?.campaignType || 'nicotine'} data={[{ label: 'Nicotine', type: 'nicotine' }, { label: 'Alcohol', type: 'alcohol' }, { label: 'Drugs', type: 'drugs' }]} ></RadioGroupRow>
                        <PrimaryButton progress={true} align={'left'} isBusy={this.state.isBusy} onPress={this.shareMe.bind(this)} icon={
                            <FontAwesome name="share" style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} />} label={'Share'} style={{
                                width: '100%',
                                marginBottom: 10,
                                marginTop: 10
                            }} />
                    </View>
                </ActionSheet>
            </View>
        )

    }
}

export default AIHomeScreen


