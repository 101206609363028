/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      isPremium
      campaignStart
      campaignType
      reports
      deviceToken
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      isPremium
      campaignStart
      campaignType
      reports
      deviceToken
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      isPremium
      campaignStart
      campaignType
      reports
      deviceToken
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserPostLikes = /* GraphQL */ `
  mutation CreateUserPostLikes(
    $input: CreateUserPostLikesInput!
    $condition: ModelUserPostLikesConditionInput
  ) {
    createUserPostLikes(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserPostLikes = /* GraphQL */ `
  mutation UpdateUserPostLikes(
    $input: UpdateUserPostLikesInput!
    $condition: ModelUserPostLikesConditionInput
  ) {
    updateUserPostLikes(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserPostLikes = /* GraphQL */ `
  mutation DeleteUserPostLikes(
    $input: DeleteUserPostLikesInput!
    $condition: ModelUserPostLikesConditionInput
  ) {
    deleteUserPostLikes(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAddixCooment = /* GraphQL */ `
  mutation CreateAddixCooment(
    $input: CreateAddixCoomentInput!
    $condition: ModelAddixCoomentConditionInput
  ) {
    createAddixCooment(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAddixCooment = /* GraphQL */ `
  mutation UpdateAddixCooment(
    $input: UpdateAddixCoomentInput!
    $condition: ModelAddixCoomentConditionInput
  ) {
    updateAddixCooment(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAddixCooment = /* GraphQL */ `
  mutation DeleteAddixCooment(
    $input: DeleteAddixCoomentInput!
    $condition: ModelAddixCoomentConditionInput
  ) {
    deleteAddixCooment(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserMessageThreads = /* GraphQL */ `
  mutation CreateUserMessageThreads(
    $input: CreateUserMessageThreadsInput!
    $condition: ModelUserMessageThreadsConditionInput
  ) {
    createUserMessageThreads(input: $input, condition: $condition) {
      id
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserMessageThreads = /* GraphQL */ `
  mutation UpdateUserMessageThreads(
    $input: UpdateUserMessageThreadsInput!
    $condition: ModelUserMessageThreadsConditionInput
  ) {
    updateUserMessageThreads(input: $input, condition: $condition) {
      id
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserMessageThreads = /* GraphQL */ `
  mutation DeleteUserMessageThreads(
    $input: DeleteUserMessageThreadsInput!
    $condition: ModelUserMessageThreadsConditionInput
  ) {
    deleteUserMessageThreads(input: $input, condition: $condition) {
      id
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserMessage = /* GraphQL */ `
  mutation CreateUserMessage(
    $input: CreateUserMessageInput!
    $condition: ModelUserMessageConditionInput
  ) {
    createUserMessage(input: $input, condition: $condition) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserMessage = /* GraphQL */ `
  mutation UpdateUserMessage(
    $input: UpdateUserMessageInput!
    $condition: ModelUserMessageConditionInput
  ) {
    updateUserMessage(input: $input, condition: $condition) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserMessage = /* GraphQL */ `
  mutation DeleteUserMessage(
    $input: DeleteUserMessageInput!
    $condition: ModelUserMessageConditionInput
  ) {
    deleteUserMessage(input: $input, condition: $condition) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAddixNotification = /* GraphQL */ `
  mutation CreateAddixNotification(
    $input: CreateAddixNotificationInput!
    $condition: ModelAddixNotificationConditionInput
  ) {
    createAddixNotification(input: $input, condition: $condition) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAddixNotification = /* GraphQL */ `
  mutation UpdateAddixNotification(
    $input: UpdateAddixNotificationInput!
    $condition: ModelAddixNotificationConditionInput
  ) {
    updateAddixNotification(input: $input, condition: $condition) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAddixNotification = /* GraphQL */ `
  mutation DeleteAddixNotification(
    $input: DeleteAddixNotificationInput!
    $condition: ModelAddixNotificationConditionInput
  ) {
    deleteAddixNotification(input: $input, condition: $condition) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAddixPost = /* GraphQL */ `
  mutation CreateAddixPost(
    $input: CreateAddixPostInput!
    $condition: ModelAddixPostConditionInput
  ) {
    createAddixPost(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      aiChatId
      aiChat {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      imagePreview
      text
      description
      imageKey
      postLink
      postItemType
      ipLikes
      type
      postType
      reports
      numLikes
      numViews
      numComments
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAddixPost = /* GraphQL */ `
  mutation UpdateAddixPost(
    $input: UpdateAddixPostInput!
    $condition: ModelAddixPostConditionInput
  ) {
    updateAddixPost(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      aiChatId
      aiChat {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      imagePreview
      text
      description
      imageKey
      postLink
      postItemType
      ipLikes
      type
      postType
      reports
      numLikes
      numViews
      numComments
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAddixPost = /* GraphQL */ `
  mutation DeleteAddixPost(
    $input: DeleteAddixPostInput!
    $condition: ModelAddixPostConditionInput
  ) {
    deleteAddixPost(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      aiChatId
      aiChat {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      imagePreview
      text
      description
      imageKey
      postLink
      postItemType
      ipLikes
      type
      postType
      reports
      numLikes
      numViews
      numComments
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAIChats = /* GraphQL */ `
  mutation CreateAIChats(
    $input: CreateAIChatsInput!
    $condition: ModelAIChatsConditionInput
  ) {
    createAIChats(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAIChats = /* GraphQL */ `
  mutation UpdateAIChats(
    $input: UpdateAIChatsInput!
    $condition: ModelAIChatsConditionInput
  ) {
    updateAIChats(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAIChats = /* GraphQL */ `
  mutation DeleteAIChats(
    $input: DeleteAIChatsInput!
    $condition: ModelAIChatsConditionInput
  ) {
    deleteAIChats(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAIThreads = /* GraphQL */ `
  mutation CreateAIThreads(
    $input: CreateAIThreadsInput!
    $condition: ModelAIThreadsConditionInput
  ) {
    createAIThreads(input: $input, condition: $condition) {
      id
      userId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAIThreads = /* GraphQL */ `
  mutation UpdateAIThreads(
    $input: UpdateAIThreadsInput!
    $condition: ModelAIThreadsConditionInput
  ) {
    updateAIThreads(input: $input, condition: $condition) {
      id
      userId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAIThreads = /* GraphQL */ `
  mutation DeleteAIThreads(
    $input: DeleteAIThreadsInput!
    $condition: ModelAIThreadsConditionInput
  ) {
    deleteAIThreads(input: $input, condition: $condition) {
      id
      userId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
