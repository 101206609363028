import { NavigationContainer } from "@react-navigation/native";

import navigationService from "../services/navigation-service";
import { AppContext, IContext } from "../store/app-provider";
import HomeScreen from './../screens/home';
import Toast from 'react-native-toast-notifications';
import * as Animatable from 'react-native-animatable';

import PrivacyScreen from './../screens/privacy';
import TermsScreen from './../screens/terms';
import MyAccountScreen from './../screens/my-account';
import MyPostsScreen from './../screens/my-posts';
import MyLikedScreen from './../screens/my-liked';
import MessageScreen from './../screens/message';
import PostScreen from './../screens/post-view';
import MessagesScreen from './../screens/messages';
import CampaignScreen from './../screens/campaign';
import AIHistoryScreen from './../screens/ai-history';
import AIStatsScreen from './../screens/ai-stats';
import AIHomeScreen from './../screens/ai-home';
import AIMessageScreen from './../screens/ai-message-view';
import LoginScreen from './../screens/login';
import { ToastProvider } from "react-native-toast-notifications";
import Header from './../components/header';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Constants from 'expo-constants';
const Stack = createNativeStackNavigator();
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Auth, graphqlOperation, Hub } from "aws-amplify";
import React from "react";
import colors from "../constants/colors";
import {
  updateAddixNotification
} from "./../graphql/mutations";
import {
  AsyncStorage,
  Keyboard,
  Modal,
  Platform,
  BackHandler,
  ScrollView,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  StatusBar,
  ImageBackground
} from "react-native";
import style from "../constants/style";
import { AntDesign, Entypo, FontAwesome, FontAwesome5, MaterialCommunityIcons, MaterialIcons, SimpleLineIcons } from "@expo/vector-icons";
import PrimaryButton from "./primary-button";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import Share from "./share";
import Alert from "./alert";
import AppMenu from "./app-menu";
import RadioGroup from "./radio-group";
import DoubleButtonBar from "./double-button-bar";
import CommentsModal from "./comments-modal";
import ItemsList from "./items-list";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import UserButton from "./user-button";
import UserButtonLong from "./user-button-long";
import ViewPostScreen from "../screens/view";
import IconMenu from "./icon-menu";

const slideInLeft = {
  from: {
    transform: [{ translateX: -280 }], // Starts offscreen 100 px to the left
  },
  to: {
    transform: [{ translateX: 0 }], // Ends at its natural position
  },
};
type SharedItem = {
  mimeType: string,
  data: string,
  extraData: any,
};

interface State {
  isBusy?: boolean;
  selectedLyricsTab: number;
  feedbackMessage?: string,
  feedbackSubject?: string,
  isBusyFeedback?: boolean
}

interface Props {

}

const link = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {

      Home: {

        path: '/',
      },
      Privacy: {

        path: 'privacy',
      },

      MyAccount: {
        path: 'my-account',
      },
      Campaign: {
        path: 'campaign',
      },
      Post: {
        path: 'post',
      },
      AIChat: {
        path: 'aimessage',
      },
      Terms: {

        path: 'terms',
      },
      'Sign In': {
        path: 'login',
      }
    },

  },
}

class AppNavigator extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  selectedUserMenu = [
    {
      label: "Message",
      icon: <FontAwesome5 name={"facebook-messenger"} size={24} color="black" />
    }, {
      label: "Block User",
      icon: <AntDesign name="flag" size={24} color="black" />
    }]
  myUserMenu = [
    {
      label: "Messages",
      icon: <FontAwesome5 name={"facebook-messenger"} size={24} color="black" />,
      screen: "Messages"
    }, {
      label: "My Campaign",
      icon: <FontAwesome5 name={"hourglass-start"} size={24} color="black" />,
      screen: "Campaign"
    },
    {
      label: "My Posts",
      icon: <FontAwesome5 name={"book"} size={24} color="black" />,
      screen: "MyPosts"
    }, {
      label: "My Liked",
      icon: <FontAwesome5 name={"heart"} size={24} color="black" />,
      screen: "MyLiked"
    }, {
      label: "Maya History",
      icon: <Entypo name="help" size={24} color="black" />,
      screen: "AIHistory"
    }, {
      label: "My Account",
      icon: <FontAwesome5 name={"user-circle"} size={24} color="black" />,
      screen: "MyAccount"
    }]
  handler
  constructor(props: any) {
    super(props);
    this.state = {
      isBusy: false,
      selectedLyricsTab: 0
    }
    this.getUserCredentials();
    if (!this.handler) {
      this.handler = Hub.listen('auth', ({ payload: { event, data } }) => {

        switch (event) {
          case 'signIn':
            console.log('auth state signIn', event);
            this.getUserCredentials();
            break;
          case 'signOut':

            break;
          case 'customOAuthState':

        }
      });
    }
  }

  async componentDidMount() {
    const handleShare = (item?: any) => {
      if (!item) {
        return;
      }
      const { mimeType, data, extraData } = item;
      if (data && data.indexOf('http') !== -1) {
        navigationService.props.initialLinkToShare = data;
      }
    }
    if (Platform.OS !== 'web') {
      navigationService.props.ShareMenu.getInitialShare(handleShare);
      navigationService.props.ShareMenu.addNewShareListener((item: any) => {
        if (!item) {
          return;
        }
        const { mimeType, data, extraData } = item;
        if (data && data.indexOf('http') !== -1) {
          this.context.setScreen("Home", true);
          this.context.setAppProp({ initialLinkToShare: data })
          navigationService.props.initialLinkToShare = null;
        }

      });
    }


  }

  async getUserCredentials() {
    let user;

    try {
      user = await Auth.currentAuthenticatedUser();
      this.context.setUser(user);
      //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(checkUser(user))
      console.log("Cognito has a user")
    } catch (e) {
      //console.log("Cognito has no user")
      //(store.dispatch as ThunkDispatch<User, void, ActionTypes>)(checkUser({ attributes: { "email": "yonodo10@gmail.com", "sub": "920a4dd7-6212-48a8-b873-b7f1a72a2901" } }))

    }

  }


  signOut = () => {
    SheetManager.hide('user_my_menu');
    this.context.signOut();

  }
  onSelectedUserMenuPress = async (item: any) => {
    switch (item.label) {
      case "Message":
        await SheetManager.hide('user_menu');
        this.context.setScreen('Message', true);
        break
      case "Block User":
        await SheetManager.hide('user_menu');
        await this.context.waitSomeSeconds(100);
        SheetManager.show('user_menu_report');
        break
    }
  }
  gotoMyScreen = (item: any) => {
    SheetManager.hide('user_my_menu');
    if (item.screen !== 'Feedback') {
      this.context.setScreen(item.screen, true);
    } else {
      setTimeout(() => {
        SheetManager.show(`feedback`);
        //this.context.showToast(`Feedback is not available on the mobile app`, 'success');
      }, 500);
    }
  }

  agreePolicy() {
    this.context.setAppProp({ hasShownPolicy: true });
    if (Platform.OS === "web") {
      localStorage.setItem("hasShownPolicy2", "true");
    } else {
      AsyncStorage.setItem("hasShownPolicy2", "true");
    }
  }
  sendFeedbackMessage = async () => {
    let result: any;
    this.setState({ isBusyFeedback: true });
    try {
      const result = (await API.post('addixrestapi', '/rest/message/', { body: { toEmail: 'contact@canversi.com', mail: this.context.state.user?.email, subject: "Feedback Addix - " + this.state.feedbackSubject, message: this.state.feedbackMessage } }))
      this.context.showToast("Feedback sent", "success");
    } catch (e) {
      //toast.show(e.response.data.message, { type: 'error' });

    }
    this.setState({ isBusyFeedback: false });
    SheetManager.hide('feedback_sheet');
  }
  gotoNotification = async (item: any) => {
    await SheetManager.hide('notifications_sheet');
    await this.context.waitSomeSeconds(100);
    const notificationData = JSON.parse(item.notificationData);
    notificationData.notificationType = item.notificationType;
    notificationData.notificationCreatedByUser = item.notificationCreatedByUser;
    notificationData.notId = item.id;
    await this.context.gotoNotification(notificationData);
    this.context.getUnseenNotifications();
  }


  navigationStateChange = (state: any) => {
    console.log("nav state", state)
    const screen = state?.routes?.[state.routes?.length - 1]?.name;
    const params = state?.routes?.[state.routes?.length - 1]?.params;
    this.context.setScreen(screen, false, params);
    console.log("navigationStateChange", screen, params);
    //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen(screen, params, true));

  }
  readyNav = () => {

    this.navigationStateChange(navigationService.getNavigation().getState())

  }

  async getNotifications(token?: string) {
    let notifications = (await API.graphql({
      query: queries.getNotificationsByTime,
      variables: { userId: this.context.state.user?.id, limit: 20, sortDirection: 'DESC', nextToken: token },
    }) as any).data.getNotificationsByTime
    notifications.items = notifications.items.filter((item: any) => item.notificationCreatedByUser);
    return { items: notifications.items, nextToken: notifications.nextToken };
  }
  render() {
    const notificationRenderItem = ({ item }: any) => {
      const notificationData = JSON.parse(item.notificationData);
      const getText = () => {
        let str;
        switch (item.notificationType) {
          case 'comment':
            str = `${item.notificationCreatedByUser?.firstName} commented "${notificationData.comment.substr(0, 20) + '...'}"`;
            break
          case 'comment-reply':
            str = `${item.notificationCreatedByUser?.firstName} replied to your comment "${notificationData.comment.substr(0, 20) + '...'}"`;
            break
          case 'like':
            str = `${item.notificationCreatedByUser?.firstName} liked your post`;
            break
          case 'message':
            if (!notificationData.imageKey) {
              str = `${item.notificationCreatedByUser?.firstName} messaged you "${notificationData.message.substr(0, 20) + '...'}"`
            } else {
              str = `${item.notificationCreatedByUser?.firstName} sent you a photo`
            }
            break
        }
        return str;
      }
      return <TouchableOpacity onPress={() => {
        this.gotoNotification(item)
      }}><View style={[style.row, {
        marginTop: 10, marginBottom: 10, padding: 20, backgroundColor: colors.pinkish, alignItems: 'flex-start',
        borderRadius: colors.borderRadius
      }]}>
          {item.notificationCreatedByUser ? <UserButton hideBorder={false} user={item.notificationCreatedByUser} size={25}></UserButton> : null}
          <Text style={{
            color: colors.textColor,
            marginTop: 2,
            marginLeft: 10,
            fontWeight: item.seen ? 'normal' : 'bold',
            fontFamily: 'Jost',
            fontSize: 16
          }}>{getText()}</Text>
        </View></TouchableOpacity>

    }
    return (

      <NavigationContainer linking={link} onReady={this.readyNav.bind(this)} onStateChange={this.navigationStateChange.bind(this)} ref={navigatorRef => {
        navigationService.setTopLevelNavigator(navigatorRef);
      }}>

        <Stack.Navigator screenOptions={{
          animationDuration: 500,
          animation: 'simple_push',
          gestureEnabled: true,
          animationTypeForReplace: 'push'

        }}>
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Sign In"
            component={LoginScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Campaign"
            component={CampaignScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Message"
            component={MessageScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Messages"
            component={MessagesScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MyPosts"
            component={MyPostsScreen}
            options={{ headerTitleAlign: 'center', title: "My Posts", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MyLiked"
            component={MyLikedScreen}
            options={{ headerTitleAlign: 'center', title: "My Likes", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MyAccount"
            component={MyAccountScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="Privacy"
            component={PrivacyScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="Terms"
            component={TermsScreen}
            options={{
              headerTitleAlign: 'center',
              title: "Addix",
              header: (props) => <Header {...props} />
            }}
          />
          <Stack.Screen
            name="AIHome"
            component={AIHomeScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Post"
            component={PostScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="AIChat"
            component={AIMessageScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="AIStats"
            component={AIStatsScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="AIHistory"
            component={AIHistoryScreen}
            options={{ headerTitleAlign: 'center', title: "Addix", header: (props) => <Header {...props} /> }}
          />

        </Stack.Navigator>
        <Toast ref={(ref: any) => global['toast'] = ref} />

        {this.context.state.appMenuOpenedBack ? <TouchableOpacity onPress={() => {

          this.context.hideMenu();

        }} style={[style.fullSize, { position: 'absolute', top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0, backgroundColor: 'rgba(0,0,0,0.3)' }]}>

        </TouchableOpacity> : null}
        {this.context.state.appMenuOpened ? <Animatable.View duration={300} ref={ref => navigationService.props.menuRef = ref} style={{ position: 'absolute', height: '100%', width: 280, top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0 }} animation={slideInLeft}>{this.context.state.appMenuOpened ? <AppMenu style={{ width: '100%', height: '100%' }} /> : null}</Animatable.View> : null}
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`user_my_menu`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20, paddingTop: 10 }]}>
            <IconMenu data={this.myUserMenu} style={{ width: '100%' }} onPress={(item: any) => {
              this.gotoMyScreen(item)
            }
            }></IconMenu>
            <PrimaryButton align={'right'} onPress={() => {
              this.signOut()
            }} icon={
              <FontAwesome style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='sign-out'></FontAwesome>} label={'Sign Out'} style={{
                width: '100%',
                marginTop: 10, marginBottom: 10
              }} />
          </View>
        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_premium_sub">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Cancel')} onCancel={async () => {
              SheetManager.hide('go_premium_sub')
            }} onAccept={async () => {
              await SheetManager.hide('go_premium_sub')
              setTimeout(() => {
                SheetManager.show('buy_premium')
              }, 100);

            }} message={('This feature is available only for premium subscribers')} acceptLabel={`Subscribe`} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_ad_view">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Buy Premium')} onCancel={async () => {
              await SheetManager.hide('go_ad_view')

              setTimeout(() => {
                SheetManager.show('buy_premium');
              }, 100);
            }} onAccept={() => {
              SheetManager.hide('go_ad_view');
              this.context.playRewardedAndGo();
            }} message={('Buy the Premium package or play a 30 seconds rewarded video ad')} acceptLabel={('Play Ad')} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="buy_premium">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <View style={[style.fullWidth, style.column, style.hcenter, { height: 150, marginTop: 10 }]}>
              <Text style={{
                marginLeft: 0,
                width: '100%',
                fontSize: 16,
                marginBottom: 10,
                fontFamily: 'Jost'
              }}>Monthly Premium Subscription - Monthly auto-renewable subscription, removes all ads from the app and has access to all premium features</Text>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.buyPremiumSubscription(false);
                }, 100);
              }} adiacentMessage={`$${this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumSubPrice"]}/month auto-renew`} label="Subscribe"></PrimaryButton>
            </View>
            {Platform.OS === 'android' ? <Text style={{ marginTop: 5 }}>
              Manage Subscription
            </Text> : null}
            {Platform.OS === 'android' ? <PrimaryButton onPress={async () => {
              await SheetManager.hide('buy_premium');
              setTimeout(() => {
                //navigate to this url https://play.google.com/store/account/subscriptions
                Linking.openURL('https://play.google.com/store/account/subscriptions');
              }, 100);
            }} label="Manage Subscription" style={{ marginTop: 10, width: '100%' }} isSecondary={true}></PrimaryButton> : null}
            <Text style={{ marginTop: 5 }}>
              Tap here to restore a previous purchased package
            </Text>
            <View style={[style.fullWidth, style.column, style.hcenter, { marginTop: 15, paddingBottom: 15, paddingTop: 0 }]}>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.restorePurchase();
                }, 100);
              }} label="Restore Purchase" style={{ width: '100%' }} isSecondary={true}></PrimaryButton>
            </View>
          </View>

        </ActionSheet>
        {
          this.context.state.shareModalVisible ? <Modal
            animationType="fade" style={{
              display: "flex",
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            transparent={true}
            visible={this.context.state.shareModalVisible}
            onRequestClose={() => {
              // Alert.alert("Modal has been closed.");

            }}
          ><TouchableOpacity onPress={() => {
            this.context.setAppProp({ shareModalVisible: false })
          }} style={style.modalOverlay}>
              <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <View style={{
                  width: 350,
                  height: 380,
                  padding: 20,
                  backgroundColor: colors.backgroundColor,
                  borderRadius: colors.borderRadius
                }}>
                  <Share onShare={() => {
                    this.context.setAppProp({ shareModalVisible: false })
                  }}></Share>
                </View>
              </View>
            </TouchableOpacity>
          </Modal> : null
        }

        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="app_review">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert style={{ width: '100%' }} cancelLabel={"Cancel"} onCancel={async () => {
              SheetManager.hide('app_review');
            }} onAccept={async () => {
              await SheetManager.hide('app_review')
              setTimeout(() => {
                this.context.appReview();
              }, 500);
            }} message={"Hey, you! We don't have a perfect app, but we're striving every day to make it better, bug fixing and bringing new and exciting features for you to enjoy. So if overall you enjoy our humble app, please kindly give us a review, it would mean the world for us! Thank you very much!"} acceptLabel={"Rate and Review"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="download_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={Platform.OS === "android" ?
              <Entypo name="google-play" size={24} color={colors.iconColorWhite} /> :
              <FontAwesome5 name="app-store" size={24} color={colors.iconColorWhite} />} cancelLabel={"Cancel"} style={{ width: '100%' }} onCancel={() => {
                SheetManager.hide('download_sheet_home');
              }} onAccept={() => {
                this.context.gotoStore();
                SheetManager.hide('download_sheet_home')
              }} message={"This feature is available only in the mobile app on Google Play and AppStore!"} acceptLabel={"Download"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="feedback_sheet">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <TextInput placeholder='Feedback subject' multiline={true} numberOfLines={1} onChangeText={(text: string) => {
              this.setState({ feedbackSubject: text });
            }} style={[style.fullWidth, style.textInput, {

              marginBottom: 15,

            }]}></TextInput>
            <TextInput placeholder='Feedback message' multiline={true} numberOfLines={3} onChangeText={(text: string) => {

              this.setState({ feedbackMessage: text });
            }} style={[style.fullWidth, style.textInput, {
              height: 100,
              marginBottom: 15,

            }]}></TextInput>
            <PrimaryButton isBusy={this.state.isBusyFeedback} align={'left'} onPress={() => {
              this.sendFeedbackMessage();
            }} icon={
              <FontAwesome style={{}} size={20} color={colors.iconColorWhite} name='send'></FontAwesome>} label={'Send'} style={{
                width: '100%',

              }} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="admin_message_sheet">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Text style={[style.fullWidth, { color: colors.textColor, padding: 0 }]}>
              {this.context.state.adminMessage}
            </Text>
          </View>

        </ActionSheet>

        {/*  <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`user_menu`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20 }]}>
            {this.menu.map((item: any, index: any) => {
              return <TouchableOpacity key={index} onPress={() => {
                this.gotoMyScreen(item)
              }} style={[style.fullWidth]}>
                <View style={[style.row, style.fullWidth, { marginTop: 10, padding: 10 }]}>
                  <FontAwesome5 name={item.icon} size={24} style={{ marginTop: 0 }} color="black" />
                  <Text style={{
                    marginLeft: 20,
                    flex: 1,
                    fontSize: 18,
                    fontFamily: 'Jost'
                  }}>{item.label}</Text>
                </View>
              </TouchableOpacity>

            })}

            <PrimaryButton align={'right'} onPress={() => {
              this.signOut()
            }} icon={
              <FontAwesome style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='sign-out'></FontAwesome>} label={'Sign Out'} style={{
                width: '100%',
                marginTop: 10, marginBottom: 10
              }} />
          </View>
        </ActionSheet> */}

        {this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false' && Platform.OS !== 'web' ?
          <Modal
            animationType="fade" style={{
              display: "flex",
              bottom: 0,
              top: 0,
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}

            transparent={true}
            visible={this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false'}

          >
            <View style={[style.fullWidth, style.column, {
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: 0,
            }]}>

              <View style={[{
                height: '100%',
                width: '100%',
                maxWidth: colors.maxWidth,
                padding: 20,
                borderRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                paddingTop: 0,
                backgroundColor: "#fff"
              }]}>
                <ScrollView style={[style.fullWidth, { padding: 20, flex: 1 }]}>
                  <Text style={[style.fullSize, { color: colors.textColor, padding: 20 }]}>
                    {this.context.state.appConfig?.privacy?.replace(/<br\/>/gi, '\n') + '\n\n'}
                    {this.context.state.appConfig?.terms?.replace(/<br\/>/gi, '\n')}
                  </Text>
                </ScrollView>
                <PrimaryButton align={'left'} onPress={this.agreePolicy.bind(this)}
                  label={'Accept and continue'} style={{ width: '100%', marginTop: 10 }} />
              </View>

            </View>
          </Modal> : null}
        <CommentsModal post={this.context.state.viewPostComments}></CommentsModal>
        <ActionSheet keyboardShouldPersistTaps={'handled'} onClose={() => {

        }} containerStyle={{
          zIndex: 9999,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius,
          maxWidth: colors.maxWidth
        }} elevation={3333} defaultOverlayOpacity={0.5} id={`notifications_sheet`}>

          <View style={[style.fullWidth, style.column, {
            height: 400,
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0)',
            padding: 0,
          }]}>
            <View style={[{
              height: '100%',
              width: '100%',
              maxWidth: colors.maxWidth,
              padding: 20,
              borderRadius: 10,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              paddingTop: 0,
              backgroundColor: "#fff"
            }]}>
              <ItemsList ref={ref => {
              }} cache={false} disableBottomScroll={true} style={{ marginTop: 10 }} renderItem={notificationRenderItem} disableRefresh={false} stateKey={'notificationsList'} fn={this.getNotifications.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.notificationsList}></ItemsList>
            </View>

          </View>
        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="user_menu_report">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={!this.context.state.isAdmin ? 'Block User' : 'Block Permanently'} onCancel={async () => {
              await SheetManager.hide('user_menu_report')
              if (this.context.state.isAdmin) {
                this.context.banUser()
              } else {
                this.context.reportUserAccount(true)
              }

            }} icon={
              <AntDesign name="flag" size={24} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                SheetManager.hide('user_menu_report')
                this.context.reportUserAccount();
              }} message={"Report user for inappropriate behaviour"} acceptLabel={"Report User"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="user_menu">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <View style={[style.row, style.vcenter, style.fullWidth, { justifyContent: 'flex-start', marginBottom: 20 }]}>
              {/*  <UserButton size={36} user={this.context.state.selectedUser}></UserButton> */}
              <UserButtonLong style={{}} hideDetails={false} user={this.context.state.selectedUser} ></UserButtonLong>
            </View>
            <IconMenu data={this.selectedUserMenu} style={{ width: '100%' }} onPress={(item: any) => {
              this.onSelectedUserMenuPress(item)
            }
            }></IconMenu>

          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="sign_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={
              <FontAwesome5 name="user" size={24} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                SheetManager.hide('sign_sheet_home')
                this.context.setScreen('Sign In', true);
              }} message={"You need to be signed in to be able to access this feature!"} acceptLabel={"Sign In"} />
          </View>

        </ActionSheet>
        {this.context.state.imagePreview ? <ViewPostScreen style={{ position: 'absolute', height: '100%', width: '100%', top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0 }}> </ViewPostScreen> : null}
      </NavigationContainer>
    )
  }
}

export default AppNavigator;
