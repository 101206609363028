/* eslint-disable */
// this is an auto generated file. This will be overwritten


export const getAllPostsByTime = /* GraphQL */ `
  query GetAllPostsByTime(
    $type: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddixPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAllPostsByTime(
      type: $type
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          user {
            id
            isPremium
            campaignStart
            campaignType
            reports
            deviceToken
            email
            picture
            firstName
            lastName
            createdAt
            updatedAt
            __typename
          }
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostsByTime = /* GraphQL */ `
  query GetPostsByTime(
    $postType: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddixPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByTime(
      postType: $postType
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          user {
            id
            isPremium
            campaignStart
            campaignType
            reports
            deviceToken
            email
            picture
            firstName
            lastName
            createdAt
            updatedAt
            __typename
          }
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

