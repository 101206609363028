
import * as React from 'react';
import { ImageBackground, StyleSheet, Text, View, ViewStyle, TouchableOpacity, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import { AddixPost } from '../models';
import { InAppBrowser } from 'react-native-inappbrowser-reborn'



interface Props {
    imagePreview: string
    link?: string
    imageSize?: number
    description: string
    style?: ViewStyle
}

interface State {

}
class LinkPreview extends React.Component<Props, State> {

    static contextType = AppContext;
    declare context: IContext
    constructor(props: any) {
        super(props);

    }

    async componentDidMount() {

    }


    componentDidUpdate(prevPops: any) {


    }



    render() {

        const busyIndicator = () => {
            /* if (this.props.app.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            } */
        }


        return (
            <TouchableOpacity onPress={() => {
                if (this.props.link) {
                    if (Platform.OS === 'web') {
                        window.open(this.props.link, '_blank');
                    } else {
                        InAppBrowser.open(this.props.link);
                    }

                }
            }} style={[this.props.style]} >
                <View style={[this.props.style, style.fullWidth, { padding: 0 }]} >
                    {this.props.imagePreview ? <ImageBackground source={{ uri: this.props.imagePreview }} imageStyle={{ borderRadius: colors.borderRadius, resizeMode: 'cover', width: '100%', height: this.props.imageSize || 300 }} style={{ width: '100%', height: this.props.imageSize || 300, marginBottom: 10 }} >

                    </ImageBackground> : <View style={[style.column, style.hcenter, style.vcenter, style.fullWidth, { height: 300, borderRadius: colors.borderRadius, backgroundColor: colors.grey, marginBottom: 10 }]}><Text style={[style.text]}>No image preview</Text></View>}
                    <Text numberOfLines={3} ellipsizeMode='tail' style={{ color: colors.textColor, fontSize: 14, textAlign: 'left', width: '100%' }} >{this.props.description}</Text>

                </View >
            </TouchableOpacity>

        )
    };

}

export default LinkPreview;

const styles = StyleSheet.create({
    input: {
        padding: 5,
        height: 35,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: colors.textColor,
        color: colors.textColor
    },
    screenItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        backgroundColor: colors.textColor
    }
});