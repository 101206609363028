import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  RefreshControl,
  FlatList,
  ActivityIndicator, Platform
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from '../services/navigation-service';
import { AppContext, IContext } from "../store/app-provider";


interface State {
  isInitialLoading: boolean
}

interface Props {
  disableBottomScroll?: boolean
  disableRefresh?: boolean
  isTopMore?: boolean
  nextTokenName: any,
  sortKey?: string
  hideNone?: boolean
  fn: any
  t?: any
  cache: boolean
  style?: ViewStyle
  renderItem: any,
  stateKey: string,
  stateItem: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
}

class ItemsList extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  scrollPosition: number = 0;
  contentHeight
  isScrollingTop: boolean = false;
  listHeight
  list: any;

  constructor(props: any) {
    super(props);
    this.contentHeight = 0;
    this.listHeight = 0;
    this.state = { isInitialLoading: false }

  }

  componentDidMount() {
    // setTimeout(() => {
    if (this.props.cache) {
      if (!this.props.stateItem.items?.length) {
        this.getListItems(true);
      }
    } else {
      this.getListItems(true);
    }
    // }, 10)

  }
  componentDidUpdate(prevProps: any, prevState: any) {
    // Assuming this.props.stateItem.items contains your list data

  }
  scrollToEnd = () => {
    // Ensure the list reference exists and is not null

    if (this.list && this.contentHeight > this.listHeight) {
      // Use a small delay to ensure the list has been updated
      console.log('scrolling to end');
      setTimeout(() => this.list.scrollToEnd({ animated: true }), 100);
    }
  }

  refresh() {
    this.getListItems(true);
  }

  getListItems = async (refresh: boolean) => {
    await this.context.getListItems(this.props.stateKey, this.props.fn, this.props.nextTokenName, refresh);
    this.setState({ isInitialLoading: true });
  }
  onContentSizeChange = (contentWidth: any, contentHeight: any) => {
    this.contentHeight = contentHeight;
    if (!this.props.disableBottomScroll) {
      if (!this.isScrollingTop) this.scrollToEnd();
      setTimeout(() => {
        this.isScrollingTop = false;
      }
        , 1000);

    }
  };

  onLayout = (event: any) => {
    this.listHeight = event.nativeEvent.layout.height;
    if (!this.props.disableBottomScroll) this.scrollToEnd();
  };
  /* handleScroll = (event: any) => {
    const position = event.nativeEvent.contentOffset.y;
    const halfwayPoint = event.nativeEvent.contentSize.height / 2;
    if (!this.props.isTopMore) {
      if (position >= halfwayPoint && this.scrollPosition < halfwayPoint) {
        // User has scrolled past halfway point
        this.handleEndReached();
      }
    } else {
      if (position <= halfwayPoint && this.scrollPosition > halfwayPoint) {
        // User has scrolled past halfway point
        this.handleEndReached();
      }
    }

    this.scrollPosition = position;
  } */
  handleScroll = (event: any) => {
    const position = event.nativeEvent.contentOffset.y;

    if (position === 0) {
      // User has reached the top, fetch new items
      if (this.props.isTopMore) {
        this.isScrollingTop = true;
        this.getListItems(false);
      }
    } else {
      const halfwayPoint = event.nativeEvent.contentSize.height / 2;
      if (!this.props.isTopMore) {
        if (position >= halfwayPoint && this.scrollPosition < halfwayPoint) {
          // User has scrolled past halfway point

          this.handleEndReached();
        }
      } else {
        /*  if (position <= halfwayPoint && this.scrollPosition > halfwayPoint) {
           // User has scrolled past halfway point
           this.isScrollingTop = true;
           this.handleEndReached();
         } */
      }
    }

    this.scrollPosition = position;
  }
  handleEndReached = () => {
    if (this.props.nextTokenName) this.getListItems(false);
  }

  render() {
    const busyIndicator = () => {
      if (this.props.stateItem.loading) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      }
    }
    return (
      <View style={[style.fullWidth, this.props.style, { flex: 1 }]}>

        {!this.props.stateItem.items?.length && !this.props.stateItem.refreshing && !this.props.stateItem.loading ?
          <View style={[style.fullWidth, style.column, style.vcenter, style.hcenter, { flex: 1 }]}>{this.props.hideNone ? null : <Text style={{ color: colors.textColor }}>Nothing to see here yet</Text>}</View> :
          <View style={[style.fullWidth, { flex: 1 }]}>

            <FlatList onContentSizeChange={(w, h) => this.onContentSizeChange(w, h)}
              onLayout={this.onLayout} bounces={true} onEndReachedThreshold={0.5} ref={(ref) => {
                this.list = ref;
              }} refreshControl={
                <RefreshControl

                  enabled={this.props.disableRefresh ? false : true}
                  refreshing={this.props.stateItem.refreshing || false}
                  onRefresh={() => {
                    this.getListItems(true);
                  }}
                />
              } horizontal={false} onScroll={this.handleScroll} onEndReached={() => {
                if (!this.props.isTopMore) this.handleEndReached();
              }} contentContainerStyle={[{
                width: '100%',
                flexDirection: 'column',
              }]} data={this.props.stateItem.items} style={[{
                flex: 1,
                width: '100%'
              }]} renderItem={this.props.renderItem} keyExtractor={(item) => item.id}
            />
            <navigationService.props.LinearGradient style={{ height: 30, width: '100%', position: 'absolute', top: 0, left: 0 }} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }}
              colors={['#ffffff', 'rgba(255,255,255,0)']}
              angle={90}

              locations={[0, 1]}></navigationService.props.LinearGradient>
            <navigationService.props.LinearGradient style={{ height: 30, width: '100%', position: 'absolute', bottom: 0, left: 0 }} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }}
              colors={['rgba(255,255,255,0)', '#ffffff']}
              angle={90}

              locations={[0, 1]}></navigationService.props.LinearGradient>
            {/* {!this.context.state.isPremium && this.context.state.checkedStore && this.context.state.adData?.hasPostBanner && Platform.OS !== 'web' ?

              <View style={[{
                marginTop: 0,
                backgroundColor: '#fff',
                height: 60,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 0,
                width: '100%'
              }]}>

                <navigationService.props.BannerAd onAdFailedToLoad={() => {

                }}
                  unitId={this.context.state.adData?.fluid_ban}
                  size={navigationService.props.BannerAdSize.BANNER}
                  requestOptions={{
                    requestNonPersonalizedAdsOnly: this.context.state.requestNonPersonalizedAdsOnly
                  }}
                />
              </View> : null} */}
          </View>
        }
        {busyIndicator()}
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default ItemsList;
