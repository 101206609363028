import * as React from 'react';
import { ActivityIndicator, StyleSheet, Text, TextInput, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import PrimaryButton from "../components/primary-button";
import { FontAwesome5 } from "@expo/vector-icons";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import Alert from "../components/alert";

interface Props {

}

interface State {
  isBusyUpdate?: boolean,
  isBusyDelete?: boolean,
  firstName?: string,
  lastName?: string,
}

class MyAccountScreen extends React.Component<Props, State> {

  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      isBusyUpdate: false,
      isBusyDelete: false
    };
  }

  async componentDidMount() {
    this.setState({
      firstName: this.context.state.user?.firstName,
      lastName: this.context.state.user?.lastName
    })
  }

  async updateAccount() {
    if (!this.state.firstName || !this.state.lastName) {
      this.context.showToast('Please provide first and last name!', 'error');
      return;
    }

    this.setState({ isBusyUpdate: true });
    await this.context.updateAccount({ firstName: this.state.firstName, lastName: this.state.lastName });
    this.setState({ isBusyUpdate: false });
    this.context.showToast('Account updated', 'success');
  }

  async reallyDelete() {
    SheetManager.hide('delete_account_sheet');
    this.setState({ isBusyDelete: true });
    await this.context.deleteUser();
  }

  deleteAccount() {
    SheetManager.show(`delete_account_sheet`);

  }

  componentDidUpdate(prevPops: any) {

  }

  render() {

    const busyIndicator = () => {
      /* if (this.props.app.isBusy) {
          return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      } */
    }
    const appBusyIndicator = () => {
      if (this.context.state.isBusy) {
        return <ActivityIndicator style={[style.busyIndicator]} size="large" color={colors.primaryColor}/>
      }
    }
    return (
      <View style={style.page}>
        <View style={[style.container, { padding: 20 }]}>
          {this.context.state.user ? <View style={[style.fullSize]}>

            <Text style={{
              width: '100%',
              textAlign: 'left',
              fontWeight: 'bold',
              marginTop: 40,
              marginBottom: 10,
              fontSize: 18
            }}>First name *</Text>
            <TextInput defaultValue={this.context.state.user.firstName} placeholder='First Name' onChangeText={(text: string) => {
              this.setState({ firstName: text })
            }} style={[style.fullWidth, style.textInput]}></TextInput>
            <Text style={{
              width: '100%',
              textAlign: 'left',
              fontWeight: 'bold',
              fontSize: 18,
              marginBottom: 10,
              marginTop: 20
            }}>Last name *</Text>
            <TextInput defaultValue={this.context.state.user.lastName} placeholder='First Name' onChangeText={(text: string) => {
              this.setState({ lastName: text })
            }} style={[style.fullWidth, style.textInput]}></TextInput>
            <View style={{ flex: 1 }}></View>
            <PrimaryButton isBusy={this.state.isBusyDelete} isSecondary={true} align={'left'} onPress={this.deleteAccount.bind(this)} icon={
              <FontAwesome5 size={20} color={colors.primaryColor} name='trash-alt'></FontAwesome5>} label={'Delete Account'} style={{
              width: '100%',
              marginTop: 10
            }}/>
            <PrimaryButton isBusy={this.state.isBusyUpdate} align={'left'} onPress={this.updateAccount.bind(this)} icon={
              <FontAwesome5 size={20} color={colors.iconColorWhite} name='save'></FontAwesome5>} label={'Update Account'} style={{
              width: '100%',
              marginTop: 10
            }}/>
          </View> : null}
          <ActionSheet containerStyle={{
            maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderRadius: colors.borderRadius
          }} defaultOverlayOpacity={0.5} id="delete_account_sheet">
            <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
              <Alert style={{ width: '100%' }} onAccept={this.reallyDelete.bind(this)} message={"Are you sure you want to delete your account? This action is irreversible!"} acceptLabel={"Delete"}/>
            </View>

          </ActionSheet>
          {appBusyIndicator()}
        </View>
      </View>

    )
  };

}

export default MyAccountScreen


