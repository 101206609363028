import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Modal, Share, Clipboard, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { AntDesign, Entypo, EvilIcons, Feather, FontAwesome, FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import { SheetManager } from "react-native-actions-sheet";
import { AddixPost } from "../models";
import config from '../aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import navigationService from '../services/navigation-service';

interface State {

  numLikes?: number,
}

interface Props {
  notPad?: boolean
  post: AddixPost,
  style?: ViewStyle
}

class ItemBar extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  reportPost() {
    if (!this.context.state.user) {
      this.context.setScreen('Sign In', true);
      return;
    }
    this.context.reportPost(this.props.post);
  }

  sharePost(post: AddixPost) {
    const url = `https://addix.mobi/post?id=${post.id}`;
    //const url = `http://localhost:19006/post?id=${post.id}`;
    const shareObj = { url, title: "" }
    this.context.shareMe(shareObj);
  }
  deletePost() {
    if (!this.context.state.user) {
      this.context.setScreen('Sign In', true);
      return;
    }
    this.context.setAppProp({ toDeletePost: this.props.post });
    SheetManager.show('delete_post');
    this.context.deletePost(this.props.post);
  }

  async onLike() {
    const post = await this.context.likePost(this.props.post);
    this.setState({ numLikes: post.numLikes || 0 });
  }

  componentDidMount() {

  }

  async shareMeme() {
    // this.context.shareMe(this.props.post);
  }
  onComment() {
    this.context.setAppProp({
      viewPostComments: this.props.post
    }, () => {
      SheetManager.show('comments-box');
    })
  }
  awaitOneSecond() {
    return new Promise(resolve => setTimeout(resolve, 200));
  }


  render() {

    return (
      <View style={[this.props.style, style.row, {
        borderTopWidth: 0,
        paddingRight: this.props.notPad ? 0 : 15,
        paddingLeft: this.props.notPad ? 0 : 15,
        borderRadius: colors.borderRadius,
        borderColor: colors.primaryColor,
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }]}>
        <TouchableOpacity onPress={this.onLike.bind(this)}>
          {/* {this.context.state.memesLiked?.indexOf(this.props.meme.id) === -1 ?
            <Feather style={{ marginLeft: 10 }} name="heart" size={24} color={colors.iconColor}/> :
            <FontAwesome name="heart" style={{ marginLeft: 10 }} size={24} color={colors.iconColor}/>
          }*/}
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, flexDirection: 'row', height: 30, alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            {!this.props.post.numLikes && !this.state.numLikes ?
              <Feather style={{}} name="heart" size={16} color={colors.greaysh} /> :
              <FontAwesome name="heart" style={{}} size={16} color={colors.greaysh} />}
            <Text style={{
              marginLeft: 10,
              fontSize: 14,
              color: colors.textColor
            }}>{this.state.numLikes || this.props.post.numLikes || 0}</Text>
          </View>
        </TouchableOpacity>

        {/* <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
          <Feather name="eye" style={{}} size={16} c color={colors.greaysh} />
          <Text style={{
            marginLeft: 10,
            fontSize: 14,
            color: colors.textColor
          }}>{this.props.post.ipLikes?.length || this.props.post.numViews || 0}</Text>
        </View> */}
        <TouchableOpacity onPress={this.onComment.bind(this)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <FontAwesome5 style={{
              marginLeft: 10,
              backgroundColor: null
            }} name="comment-dots" size={16} color={colors.greaysh} />

            <Text style={{ marginLeft: 10, fontSize: 14, color: colors.textColor }}>{this.props.post.numComments || 0}</Text>
          </View>
        </TouchableOpacity>
        {/* <TouchableOpacity onPress={this.onComment.bind(this, this.props.meme)}>
          <FontAwesome5 style={{
            marginLeft: 10,
            backgroundColor: null
          }} name="comment-dots" size={24} color={colors.iconColor}/>
        </TouchableOpacity>*/}

        {/* <Text style={{
          marginLeft: 10,
          fontSize: 20,
          color: colors.textColor
        }}>{this.props.meme.numComments || 0}</Text>*/}
        <TouchableOpacity onPress={this.reportPost.bind(this, this.props.post)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <AntDesign style={{

            }} name="flag" size={16} color={colors.greaysh} />
          </View>
        </TouchableOpacity>

        {(this.context.state.isAdmin || this.props.post.userId === this.context.state.user?.id) && <TouchableOpacity onPress={this.deletePost.bind(this, this.props.post)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <MaterialIcons style={{
            }} name="delete" size={16} color={this.props.post.user?.deviceToken && this.context.state.isAdmin ? colors.bluePrimary : colors.greaysh} />
          </View>
        </TouchableOpacity>}
        <TouchableOpacity onPress={this.sharePost.bind(this, this.props.post)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <Feather name="share-2" style={{}} size={16} color={colors.greaysh} />
          </View>
        </TouchableOpacity>


        {/* <TouchableOpacity onPress={this.shareMeme.bind(this, this.props.lyri)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <Feather name="share-2" style={{}} size={16} color={colors.greaysh} />
          </View>
        </TouchableOpacity> */}


      </View>
    )
  };

}

const styles = StyleSheet.create({});

export default ItemBar;
