import { AntDesign, Entypo, Feather, FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  FlatList,
  Platform,
  Clipboard,
  RefreshControl,
  AsyncStorage, Image, Modal, BackHandler, ScrollView, TextInput, ActivityIndicator, Keyboard, ImageBackground
} from 'react-native';
import colors from '../constants/colors';
import { Buffer } from 'buffer';
import style from '../constants/style';
import navigationService from './../services/navigation-service';
import { AppContext, IContext } from '../store/app-provider';
import * as queries from "./../graphql/queries";
import * as myqueries from "./../graphql/my-queries";
import ItemsList from "../components/items-list";
import { API, Storage } from "aws-amplify";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import RadioGroupRow from '../components/radio-group-row';
import PrimaryButton from '../components/primary-button';
import PostItem from '../components/post-item';
import LinkPreview from '../components/link-preview';
///@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import IconMenu from '../components/icon-menu';
interface Props {

}

interface State {
  savePhotoUrl?: string
  isBusyLink?: boolean
  type: string
  postMessage?: string
  postLink?: string
  metatags?: any
  isBusy?: boolean
}

class HomeScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  itemList: any;
  quitType: any = { label: 'Nicotine', type: 'nicotine' }
  quitTypeHistory: any = { label: 'All', type: 'all' }
  savePhotoKey: string = "";


  constructor(props: any) {
    super(props);
    this.state = { type: 'public' }
  }
  resetState() {
    return new Promise((resolve, reject) => {
      this.setState({ postLink: "", metatags: null, postMessage: "", savePhotoUrl: "" }, () => {
        resolve(true);
      });
    })
  }


  async createPost(force?: boolean) {
    if (Platform.OS === "web" && (window as any)?.document.location.href?.indexOf('localhost') === -1) {
      SheetManager.show('download_sheet_home');
      return;
    }
    let generatedActions = 0;
    if (Platform.OS !== "web") {
      generatedActions = parseInt(await AsyncStorage.getItem("generatedActions") || "0") || 0;
    } else {
      generatedActions = 0;
    }
    if (!force && generatedActions % this.context.state.adData?.genBetween === 0 && generatedActions >= this.context.state.adData?.maxGen && !this.context.state.isPremium && this.context.state.adData?.enabled && this.context.state.checkedStore && Platform.OS !== 'web') {
      this.context.showRewarded(this.createPost.bind(this, true))
      return;
    }
    SheetManager.show('post_menu');
    generatedActions++;
    AsyncStorage.setItem("generatedActions", generatedActions.toString());

    const hasReview = await AsyncStorage.getItem(this.context.state.appConfig.reviewKey || "hasReview");
    if (!hasReview && generatedActions >= this.context.state.appConfig.reviewAfterMessagesNumber) {
      AsyncStorage.setItem(this.context.state.appConfig.reviewKey || "hasReview", "true");
      SheetManager.show('app_review');
    }

    this.context.setAppProp({ generatedActions });
  }
  getDownloadCondition() {
  }
  async componentDidMount() {
    navigationService.props.setHome = true;
  }

  async componentDidUpdate(prevPops: any) {
    if (this.context.state?.initialLinkToShare) {

      const link = this.context.state.initialLinkToShare;
      this.context.state.initialLinkToShare = "";
      this.context.setAppProp('initialLinkToShare', "");

      this.setState({ postLink: link }, () => {
        this.previewLink();
      });
      SheetManager.show('post_link_sheet');
    }
  }


  async getHistory(token?: string) {
    let history = this.quitTypeHistory.type === "all" ? (await API.graphql({
      query: myqueries.getAllPostsByTime,
      variables: { type: "public", limit: 20, sortDirection: 'DESC', nextToken: token },
    }) as any).data.getAllPostsByTime : (await API.graphql({
      query: myqueries.getPostsByTime,
      variables: { postType: this.quitTypeHistory.type, limit: 20, sortDirection: 'DESC', nextToken: token },
    }) as any).data.getPostsByTime;
    //console.log(JSON.stringify(history));
    history.items = history.items.filter((item: any) => item.user);
    return { items: history.items, nextToken: history.nextToken };
  }

  reportUser() {
  }

  setPrivate(e: any) {
    if (e.type !== this.state.type) {
      this.setState({ type: e.type }, () => {
        this.itemList.refresh();
      })
    }

  }
  async onMenuPress(item: any) {
    await this.resetState();
    await SheetManager.hide('post_menu');
    await this.context.waitSomeSeconds(100);
    if (!this.context.state.user) {
      SheetManager.show('sign_sheet_home');
      return;
    }
    switch (item.label) {
      case "Start Quitting Campaign":
        SheetManager.show('quit_sheet');
        break;
      case "Reset Quitting Campaign":
        SheetManager.show('quit_sheet');
        break;
      case "Post Text":
        SheetManager.show('post_message_sheet');
        break;
      case "Post Link":
        SheetManager.show('post_link_sheet');
        break;
      case "Ask Maya":
        if (this.context.state.user?.isPremium || this.context.state.isAdmin) {
          this.context.setScreen("AIHome", true);
        } else {
          SheetManager.show('go_premium_sub');
        }
        break;
      case "Post Image":
        this.selectPhoto();
        break;
    }
  }

  async selectPhoto() {
    const result = await navigationService.props.launchImageLibrary({
      maxWidth: 600,
      maxHeight: 1100,
      mediaType: 'photo', cameraType: 'back'
    });
    //save the resulting image to s3
    if (!result.didCancel) {
      const photo = await navigationService.props.RNFS.readFile(result.assets?.[0].uri || "", 'base64')
      const photoBlob = Buffer.from(photo, 'base64');
      const photoExtension = result.assets?.[0].uri?.split('.').pop();
      const key = `${uuidv4()}.${photoExtension}`
      this.savePhotoKey = key;
      const response = await Storage.put(key, photoBlob, {
        contentType: result.assets?.[0].type,
        level: 'public',
        progressCallback: (progress: any) => {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
      });
      const savePhotoUrl = await Storage.get(key, { level: 'public' });
      this.setState({ savePhotoUrl: savePhotoUrl, postMessage: "" }, async () => {
        await SheetManager.hide('post_menu');
        await this.context.waitSomeSeconds(100);
        SheetManager.show('post_photo_sheet');
      });
    }
  }
  async postMessage() {
    if (!this.state.postMessage) {
      this.context.showToast('Message is required', 'error');
      return;
    }
    this.setState({ isBusy: true });
    await this.context.postText(this.state.postMessage || "", this.quitType);
    this.setState({ isBusy: false });
    SheetManager.hide('post_message_sheet');
    this.context.showToast('Message posted successfully', 'success');
  }
  async postLink() {
    if (!this.state.postLink) {
      this.context.showToast('Link is required', 'error');
      return;
    }
    this.setState({ isBusy: true });
    await this.context.postLink(this.state.metatags, this.state.postLink || "", this.state.postMessage || "", this.quitType);
    this.setState({ isBusy: false, postLink: "", postMessage: "" });
    SheetManager.hide('post_link_sheet');
    this.context.showToast('Link posted successfully', 'success');
    this.resetState();
  }
  async postPhoto() {
    this.setState({ isBusy: true });
    await this.context.postPhoto(this.savePhotoKey, this.state.postMessage || "", this.quitType);
    this.setState({ isBusy: false });
    SheetManager.hide('post_photo_sheet');
    this.context.showToast('Image posted successfully', 'success');
  }
  async previewLink() {
    let metatags
    this.setState({ isBusyLink: true });
    try {
      metatags = (await API.post('addixrestapi', `/rest/get-meta-tags`, {
        body: { url: this.state.postLink }
      })).metatags;
      this.setState({ metatags });
      console.log("metatags", metatags)
    } catch (e) {

    }
    this.setState({ isBusyLink: false });
  }

  render() {
    const busyIndicatorLink = () => {
      if (this.state.isBusyLink) {
        return <ActivityIndicator size="large" color={colors.busyIndicator} />
      }
    }
    const renderItem = ({ item, index }: any) => {
      return <PostItem index={index} onPress={() => {
      }} item={item} />

    }
    const menu = [
      {
        label: this.context.state.user?.campaignType ? "Reset Quitting Campaign" : "Start Quitting Campaign",
        icon: <MaterialIcons name="smoke-free" size={24} color="black" />
      }, {
        label: "Post Text",
        icon: <Ionicons name="text" size={24} color="black" />
      },
      {
        label: "Post Link",
        icon: <Entypo name="link" size={24} color="black" />
      }, {
        label: "Post Image",
        icon: <Entypo name="image" size={24} color="black" />
      }, {
        label: "Ask Maya",
        icon: <Entypo name="help" size={24} color="black" />,
        screen: "AIHome"
      }]

    return (
      <View style={style.page}>
        <View style={[style.container, { padding: 10 }]}>
          <RadioGroupRow style={{ marginBottom: 10 }} onSelect={(data: any) => {
            this.quitTypeHistory = data;
            this.itemList.refresh();
          }} initial={0} itemWidth={75} data={[{ label: 'All', type: 'all' }, { label: 'Nicotine', type: 'nicotine' }, { label: 'Alcohol', type: 'alcohol' }, { label: 'Drugs', type: 'drugs' }]} ></RadioGroupRow>
          <ItemsList style={{ marginBottom: 0 }} ref={ref => {
            this.itemList = ref
          }} cache={false} disableBottomScroll={true} renderItem={renderItem} disableRefresh={false} stateKey={'appList'} fn={this.getHistory.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.appList}></ItemsList>
          <TouchableOpacity onPress={() => {
            this.createPost();
          }}
            style={[{
              borderWidth: 0,
              borderColor: colors.bluePrimary,
              alignItems: 'center',
              justifyContent: 'center',
              width: 60,
              position: 'absolute',
              bottom: 10,
              marginLeft: -25,
              left: '50%',
              height: 60,
              backgroundColor: colors.bluePrimary,
              borderRadius: 120,
            }]}
          >

            <Ionicons name="add" size={40} color={"#ffffff"} />

          </TouchableOpacity>
        </View>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`post_menu`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20, paddingBottom: 10, paddingTop: 10 }]}>
            <IconMenu data={menu} style={{ width: '100%' }} onPress={(item: any) => {
              this.onMenuPress(item)
            }}></IconMenu>
          </View>
        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`quit_sheet`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20, paddingBottom: 10 }]}>
            <Text style={[style.text, { marginBottom: 20, fontWeight: 'bold', fontSize: 20 }]}>Select the type of addiction you want to quit</Text>
            <RadioGroupRow style={{ marginBottom: 10 }} itemWidth={75} onSelect={(data: any) => {
              console.log(data)
              this.quitType = data;

            }} initial={0} data={[{ label: 'Nicotine', type: 'nicotine' }, { label: 'Alcohol', type: 'alcohol' }, { label: 'Drugs', type: 'drugs' }]} ></RadioGroupRow>
            <PrimaryButton progress={false} isBusy={this.state.isBusy} onPress={async () => {
              this.setState({ isBusy: true });
              await this.context.startQuittingCampaign(this.quitType);
              this.setState({ isBusy: false });
              SheetManager.hide('quit_sheet');
              this.context.showToast('Quitting campaign started successfully', 'success');
              this.context.setScreen('Campaign', true);
            }} label="Start new quitting campaign" style={{ width: '100%' }}></PrimaryButton>
          </View>
        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`post_message_sheet`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20, paddingBottom: 10 }]}>
            <TextInput
              autoCapitalize={'sentences'}
              value={this.state.postMessage}
              autoCorrect={false}
              blurOnSubmit={true}
              maxLength={250}
              enablesReturnKeyAutomatically={true}
              onSubmitEditing={() => {
                this.postMessage()

              }}
              placeholder='Post message'
              multiline={true}
              numberOfLines={3}
              onChangeText={(text: string) => {
                if (this.state.isBusy) return;
                this.setState({ postMessage: text });
              }}
              style={[style.textInput, {
                backgroundColor: colors.pinkish,
                textAlignVertical: 'center',
                height: 80,
                flexGrow: 1,
                fontFamily: 'Jost',
                marginBottom: 10,
                color: colors.neutralBlue,
                width: '100%', // Add this line
              }]}
            ></TextInput>
            <RadioGroupRow style={{ marginBottom: 10 }} itemWidth={75} onSelect={(data: any) => {
              this.quitType = data;
            }} value={this.context.state.user?.campaignType || 'nicotine'} data={[{ label: 'Nicotine', type: 'nicotine' }, { label: 'Alcohol', type: 'alcohol' }, { label: 'Drugs', type: 'drugs' }]} ></RadioGroupRow>

            <PrimaryButton progress={false} isBusy={this.state.isBusy} onPress={async () => {
              this.postMessage()
            }} label="Post Message" style={{ width: '100%' }}></PrimaryButton>
          </View>
        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`post_link_sheet`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20, paddingBottom: 10 }]}>
            <TextInput
              value={this.state.postLink}
              autoCorrect={false}
              blurOnSubmit={true}
              maxLength={800}
              enablesReturnKeyAutomatically={true}
              onSubmitEditing={() => {
                this.previewLink()
              }}
              placeholder='Link'
              multiline={false}
              numberOfLines={1}
              onChangeText={(text: string) => {
                if (this.state.isBusy) return;
                const prevText = this.state.postLink || "";

                this.setState({ postLink: text }, () => {
                  if (text.length - prevText.length > 1) {
                    console.log("pasted")
                    this.previewLink();
                  }

                });
              }}
              style={[style.textInput, {
                backgroundColor: colors.pinkish,
                textAlignVertical: 'center',
                fontFamily: 'Jost',
                marginBottom: 10,
                width: '100%', // Add this line
              }]}

            ></TextInput>
            {busyIndicatorLink()}
            {this.state.metatags ? <TextInput
              autoCapitalize={'sentences'}
              value={this.state.postMessage}
              autoCorrect={false}
              blurOnSubmit={true}
              maxLength={250}
              enablesReturnKeyAutomatically={true}
              onSubmitEditing={() => {
                this.postLink()
              }}
              placeholder='Message'
              multiline={true}
              numberOfLines={3}
              onChangeText={(text: string) => {
                if (this.state.isBusy) return;
                this.setState({ postMessage: text });
              }}
              style={[style.textInput, {
                backgroundColor: colors.pinkish,
                textAlignVertical: 'center',
                height: 80,
                flexGrow: 1,
                fontFamily: 'Jost',
                marginBottom: 10,
                color: colors.neutralBlue,
                width: '100%', // Add this line
              }]}
            ></TextInput> : null}
            {this.state.metatags ? <LinkPreview style={{ width: '100%' }} description={this.state.metatags.description} imagePreview={this.state.metatags.image}></LinkPreview> : null}

            <RadioGroupRow style={{ marginBottom: 10 }} itemWidth={75} onSelect={(data: any) => {
              this.quitType = data;
            }} value={this.context.state.user?.campaignType || 'nicotine'} data={[{ label: 'Nicotine', type: 'nicotine' }, { label: 'Alcohol', type: 'alcohol' }, { label: 'Drugs', type: 'drugs' }]} ></RadioGroupRow>

            <PrimaryButton progress={false} isBusy={this.state.isBusy} onPress={async () => {
              this.postLink()
            }} label="Post Link" style={{ width: '100%' }}></PrimaryButton>

          </View>
        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`post_photo_sheet`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20, paddingBottom: 10 }]}>
            <TextInput
              value={this.state.postMessage}
              autoCorrect={false}
              blurOnSubmit={true}
              maxLength={800}
              enablesReturnKeyAutomatically={true}
              onSubmitEditing={() => {
                this.postPhoto()
              }}
              placeholder='Message'
              multiline={true}
              numberOfLines={1}
              onChangeText={(text: string) => {
                if (this.state.isBusy) return;
                this.setState({ postMessage: text });
              }}
              style={[style.textInput, {
                backgroundColor: colors.pinkish,
                textAlignVertical: 'center',
                flexGrow: 1,
                fontFamily: 'Jost',
                marginBottom: 10,
                color: colors.neutralBlue,
                width: '100%', // Add this line
              }]}

            ></TextInput>
            <ImageBackground source={{ uri: this.state.savePhotoUrl }} imageStyle={{ borderRadius: colors.borderRadius, resizeMode: 'cover', width: '100%', height: 300 }} style={{ width: '100%', height: 300, marginBottom: 10 }} >

            </ImageBackground>

            <RadioGroupRow style={{ marginBottom: 10 }} itemWidth={75} onSelect={(data: any) => {
              this.quitType = data;
            }} value={this.context.state.user?.campaignType || 'nicotine'} data={[{ label: 'Nicotine', type: 'nicotine' }, { label: 'Alcohol', type: 'alcohol' }, { label: 'Drugs', type: 'drugs' }]} ></RadioGroupRow>

            <PrimaryButton progress={false} isBusy={this.state.isBusy} onPress={async () => {
              this.postPhoto()
            }} label="Post Link" style={{ width: '100%' }}></PrimaryButton>

          </View>
        </ActionSheet>
      </View>
    )

  }
}

export default HomeScreen


