/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      isPremium
      campaignStart
      campaignType
      reports
      deviceToken
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      isPremium
      campaignStart
      campaignType
      reports
      deviceToken
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      isPremium
      campaignStart
      campaignType
      reports
      deviceToken
      email
      picture
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserPostLikes = /* GraphQL */ `
  subscription OnCreateUserPostLikes(
    $filter: ModelSubscriptionUserPostLikesFilterInput
  ) {
    onCreateUserPostLikes(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserPostLikes = /* GraphQL */ `
  subscription OnUpdateUserPostLikes(
    $filter: ModelSubscriptionUserPostLikesFilterInput
  ) {
    onUpdateUserPostLikes(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserPostLikes = /* GraphQL */ `
  subscription OnDeleteUserPostLikes(
    $filter: ModelSubscriptionUserPostLikesFilterInput
  ) {
    onDeleteUserPostLikes(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        aiChatId
        aiChat {
          id
          userId
          threadId
          assistantId
          assistantMessage
          userMessage
          type
          savedAt
          createdAt
          updatedAt
          __typename
        }
        imagePreview
        text
        description
        imageKey
        postLink
        postItemType
        ipLikes
        type
        postType
        reports
        numLikes
        numViews
        numComments
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAddixCooment = /* GraphQL */ `
  subscription OnCreateAddixCooment(
    $filter: ModelSubscriptionAddixCoomentFilterInput
  ) {
    onCreateAddixCooment(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAddixCooment = /* GraphQL */ `
  subscription OnUpdateAddixCooment(
    $filter: ModelSubscriptionAddixCoomentFilterInput
  ) {
    onUpdateAddixCooment(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAddixCooment = /* GraphQL */ `
  subscription OnDeleteAddixCooment(
    $filter: ModelSubscriptionAddixCoomentFilterInput
  ) {
    onDeleteAddixCooment(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserMessageThreads = /* GraphQL */ `
  subscription OnCreateUserMessageThreads(
    $filter: ModelSubscriptionUserMessageThreadsFilterInput
  ) {
    onCreateUserMessageThreads(filter: $filter) {
      id
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserMessageThreads = /* GraphQL */ `
  subscription OnUpdateUserMessageThreads(
    $filter: ModelSubscriptionUserMessageThreadsFilterInput
  ) {
    onUpdateUserMessageThreads(filter: $filter) {
      id
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserMessageThreads = /* GraphQL */ `
  subscription OnDeleteUserMessageThreads(
    $filter: ModelSubscriptionUserMessageThreadsFilterInput
  ) {
    onDeleteUserMessageThreads(filter: $filter) {
      id
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserMessage = /* GraphQL */ `
  subscription OnCreateUserMessage(
    $filter: ModelSubscriptionUserMessageFilterInput
  ) {
    onCreateUserMessage(filter: $filter) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserMessage = /* GraphQL */ `
  subscription OnUpdateUserMessage(
    $filter: ModelSubscriptionUserMessageFilterInput
  ) {
    onUpdateUserMessage(filter: $filter) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserMessage = /* GraphQL */ `
  subscription OnDeleteUserMessage(
    $filter: ModelSubscriptionUserMessageFilterInput
  ) {
    onDeleteUserMessage(filter: $filter) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAddixNotification = /* GraphQL */ `
  subscription OnCreateAddixNotification(
    $filter: ModelSubscriptionAddixNotificationFilterInput
  ) {
    onCreateAddixNotification(filter: $filter) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAddixNotification = /* GraphQL */ `
  subscription OnUpdateAddixNotification(
    $filter: ModelSubscriptionAddixNotificationFilterInput
  ) {
    onUpdateAddixNotification(filter: $filter) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAddixNotification = /* GraphQL */ `
  subscription OnDeleteAddixNotification(
    $filter: ModelSubscriptionAddixNotificationFilterInput
  ) {
    onDeleteAddixNotification(filter: $filter) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAddixPost = /* GraphQL */ `
  subscription OnCreateAddixPost(
    $filter: ModelSubscriptionAddixPostFilterInput
  ) {
    onCreateAddixPost(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      aiChatId
      aiChat {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      imagePreview
      text
      description
      imageKey
      postLink
      postItemType
      ipLikes
      type
      postType
      reports
      numLikes
      numViews
      numComments
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAddixPost = /* GraphQL */ `
  subscription OnUpdateAddixPost(
    $filter: ModelSubscriptionAddixPostFilterInput
  ) {
    onUpdateAddixPost(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      aiChatId
      aiChat {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      imagePreview
      text
      description
      imageKey
      postLink
      postItemType
      ipLikes
      type
      postType
      reports
      numLikes
      numViews
      numComments
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAddixPost = /* GraphQL */ `
  subscription OnDeleteAddixPost(
    $filter: ModelSubscriptionAddixPostFilterInput
  ) {
    onDeleteAddixPost(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      aiChatId
      aiChat {
        id
        userId
        user {
          id
          isPremium
          campaignStart
          campaignType
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          createdAt
          updatedAt
          __typename
        }
        threadId
        assistantId
        assistantMessage
        userMessage
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      imagePreview
      text
      description
      imageKey
      postLink
      postItemType
      ipLikes
      type
      postType
      reports
      numLikes
      numViews
      numComments
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAIChats = /* GraphQL */ `
  subscription OnCreateAIChats($filter: ModelSubscriptionAIChatsFilterInput) {
    onCreateAIChats(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAIChats = /* GraphQL */ `
  subscription OnUpdateAIChats($filter: ModelSubscriptionAIChatsFilterInput) {
    onUpdateAIChats(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAIChats = /* GraphQL */ `
  subscription OnDeleteAIChats($filter: ModelSubscriptionAIChatsFilterInput) {
    onDeleteAIChats(filter: $filter) {
      id
      userId
      user {
        id
        isPremium
        campaignStart
        campaignType
        reports
        deviceToken
        email
        picture
        firstName
        lastName
        createdAt
        updatedAt
        __typename
      }
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAIThreads = /* GraphQL */ `
  subscription OnCreateAIThreads(
    $filter: ModelSubscriptionAIThreadsFilterInput
  ) {
    onCreateAIThreads(filter: $filter) {
      id
      userId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAIThreads = /* GraphQL */ `
  subscription OnUpdateAIThreads(
    $filter: ModelSubscriptionAIThreadsFilterInput
  ) {
    onUpdateAIThreads(filter: $filter) {
      id
      userId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAIThreads = /* GraphQL */ `
  subscription OnDeleteAIThreads(
    $filter: ModelSubscriptionAIThreadsFilterInput
  ) {
    onDeleteAIThreads(filter: $filter) {
      id
      userId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
